/*
=========================================================
* Nova erp React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Hiperbite (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Nova erp React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Nova erp React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import team1 from "assets/images/vectors/accountant/15-52-51.png";
import team2 from "assets/images/vectors/accountant/15-53-10.png";
import team3 from "assets/images/vectors/accountant/15-53-15.png"
import team4 from "assets/images/vectors/accountant/15-53-23.png"
import team5 from "assets/images/vectors/accountant/5362163.jpg"
import team6 from "assets/images/vectors/accountant/5695490.jpg"

function Modules() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              Principais Módulos
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
              A Nova ERP tem como objetivo fornecer informações precisas e relevantes sobre a
              situação financeira e o desempenho da sua empresa apartir dos principais módulos
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team1}
                name="Registo de Transacções Financeiras"
                position={{ color: "info", label: "Gestão de transações" }}
                description="O sistema permite que as empresas registrem 
                e classifiquem todas as transacções financeiras, como receitas, despesas, compras e 
                vendas."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team2}
                name="Controle de Activos e Passivos"
                position={{ color: "info", label: "Gestão de bens, direitos e obrigações " }}
                description="O sistema ajuda a manter uma visão clara do 
                património líquido e da saúde financeira da organização"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team3}
                name="Geração de Demonstrações Financeiras"
                position={{ color: "info", label: "Gráficos de desempenho financeiro" }}
                description="O sistema permite gerar demonstrações financeiras, como o Balanço 
                Patrimonial, a Demonstração de Resultados e o Fluxo de Caixa"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team4}
                name="Facturamento e Cobrança"
                position={{ color: "info", label: "Gestão de Facturas" }}
                description="O sistema possui funcionalidades de facturação, permitindo 
                as empresas gerar facturas para seus clientes e rastreiar pagamentos 
                recebidos"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team5}
                name="Gerenciamento de Impostos"
                position={{ color: "info", label: "Gestão por obrigações fiscais" }}
                description="O sistema permite calcular e acompanhar impostos devidos, como 
                impostos sobre vendas, impostos de renda e outros tributos"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team6}
                name="Conciliação Bancária"
                position={{ color: "info", label: "Gestão de Discrepâncias e possíveis erros" }}
                description="O sistema permite importar extractos bancários e ajudar na conciliação entre as 
                transacções registradas no sistema e as transacções bancárias reais"
              />
            </MKBox>
          </Grid>
          
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Modules;
