// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";

// Nova erp React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logos/dark.png";
import { Instagram, LinkedIn } from "@mui/icons-material";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Nova erp",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/hipertite/",
    },
    {
      icon: <Instagram />,
      link: "https://www.instagram.com/hiperbite/",
    },
    {
      icon: <GitHubIcon />,
      link: "https://github.com/Hiperbite",
    },
    {
      icon: <LinkedIn />,
      link: "https://www.linkedin.com/company/hiperbite",
    },
  ],
  menus: [
    {
      name: "Hiperbite",
      items: [
        { name: "sobre nós", href: "https://hiperbite.ao/#about" },
        { name: "Mais soluções", href: "https://hiperbite.ao/#works" },
        { name: "Parceiros", href: "https://hiperbite.ao/#testimonial" },
        { name: "blog", href: "https://hiperbite.ao/#news" },
      ],
    },
    {
      name: "Produtos",
      items: [
        {
          name: "Recursos Humanos",
          route: "/products/humans-resources",
        },
        {
          name: "Vendas",
          route: "/products/sales",
        },
        {
          name: "Contabilidade",
          route: "/products/accounting",
        },
        {
          name: "Stock",
          route: "/products/stock",
        },
      ],
    },
    {
      name: "Suporte & contactos",
      items: [
        {
          name: "Centro de Ajuda",
          route: "/suport/help-center",
        },
        {
          name: "Tutoriais em Vídeo",
          route: "/suport/help-center#tutorials",
        },
        {
          name: "Suporte Técnico",
          route: "/suport/help-center#user-guide",
        },
        {
          name: "FAQs",
          route: "/suport/faqs",
        },

      ],
    },
    {
      name: "legal",
      items: [
        { name: "Termos & Condições", route: "/legal/terms-and-conditions" },
        { name: "Politicas de Privacidade", route: "/legal/privacy-policies" },
        { name: "Licenses (EULA)", route: "/legal/licenses" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      Todos os direitos reservados. Copyright &copy; {date} Nova erp por{" "}
      <MKTypography
        component="a"
        href="https://www.hiperbite.ao"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Hiperbite
      </MKTypography>
      .
    </MKTypography>
  ),
};
