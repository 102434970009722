import { useMemo, useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import PricingCards from "../components/PricingCard";
import { appsPricings } from "pages/Suport/Legal/LegalPage/sections/PricingMain";
const appsPricingsx: any[] = [
  //rh
  [
    {
      active: false,
      color: "dark",
      title: "Flex",
      description: "2-10 Funcionários",
      price: { value: 998.00, type: "mês" },
      action: { type: "internal", route: "/", label: "Adquira já" },
      specifications: [
        'Gestão de Ausencias e Pontos'
      ]
    },
    {
      active: true,
      color: "dark",
      title: "Basic",
      description: "até 30 Funcionários",
      price: { value: 6400.00, type: "mês" },
      action: { type: "internal", route: "/", label: "Aduira já" },
      specifications: [
        'Gestão folhas de Salarios',
        'Gestão de Ausencias e Pontos'
      ]
    },
    {

      active: false,
      color: "dark",
      title: "Profissional",
      description: "60 Funcionários",
      price: { value: 3599.00, type: "mês" },
      action: { type: "internal", route: "/", label: "Adquira já" },
      specifications: [
        'Gestão de Ausencias e Pontos',
        'Recrutamento e seleção',
      ]
    },
    {
      active: false,
      color: "dark",
      title: "Premium",
      description: "até 30 Funcionários",
      price: { value: '', type: '' },
      action: { type: "internal", route: "/", label: "Aduira já" },
      specifications: [
        'Recrutamento e seleção',
        'Avalidação e desenpenho',
        'Gestão de Ausencias e Pontos'
      ]
    }
  ],
  //seles
  [
    {
      active: true,
      color: "dark",
      title: "Lite",
      description: "100 Facturas/mes",
      price: { value: 998.00, type: "mês" },
      action: { type: "internal", route: "/", label: "Adquira já" },
      specifications: [
      //  'Controlo de Stocks'
      ]
    },
    {
      active: false,
      color: "dark",
      title: "Flex",
      description: "300 Facturas/mes",
      price: { value: 6644.00, type: "mês" },
      action: { type: "internal", route: "/", label: "Adquira já" },
      specifications: [
        //'Controlo de Stock'
      ]
    },
    {
      active: true,
      color: "dark",
      title: "Basic",
      description: "500 Facturas/mes",
      price: { value: 10780.00, type: "mês" },
      action: { type: "internal", route: "/", label: "Aduira já" },
      specifications: [
        'POS',
        'Controlo de Stock'
      ]
    },
    {

      active: true,
      color: "dark",
      title: "Profissional",
      description: "ilimitadas Facturas/mes",
      price: { value: 22008.00, type: "mês" },
      action: { type: "internal", route: "/", label: "Adquira já" },
      specifications: [
        'POS',
        'Controlo de Stock',
        'Relatórios'
      ]
    },
    {
      active: false,
      color: "dark",
      title: "Premium",
      description: "até 30 Funcionários",
      price: { value: '', type: '' },
      action: { type: "internal", route: "/", label: "Aduira já" },
      specifications: [
        'Recrutamento e seleção',
        'Avalidação e desenpenho',
        'Gestão de Ausencias e Pontos'
      ]
    }
  ],
  [
    {
      active: true,
      color: "dark",
      title: "Profissional",
      description: "Incluido no pacote Basic do Modulo Vendas",
      price: { value: 0, type: "mês" },
      action: { type: "internal", route: "/", label: "Adquira já" },
      specifications: [
        'Controlo de Stocks'
      ]
    },
    {
      active: false,
      color: "dark",
      title: "Flex",
      description: "300 Facturas/mes",
      price: { value: 998.00, type: "mês" },
      action: { type: "internal", route: "/", label: "Adquira já" },
      specifications: [
        'Controlo de Stock'
      ]
    }
  ],
  [
    {
      active: true,
      color: "info",
      title: "Básico",
      description: "6 Slot de empresas",
      price: { value: 5200.00, type: "mês" },
      action: { type: "internal", route: "/", label: "Adquira já" },
      specifications: [
        '1 Utilizador'
      ]
    },
    {
      active: true,
      color: "dark",
      title: "Profissional",
      description: "Ilimitadas Slot de empresas",
      price: { value: 8000.00, type: "mês" },
      action: { type: "internal", route: "/", label: "Adquira já" },
      specifications: [
        '6 Utilizadores'
      ]
    }]

]
const appsHubs = [
  { key: 'rh', text: 'Recursos Humanos' },
  { key: 'seles', text: 'Facturação' },
  { key: 'stock', text: 'Gestão de Stock' },
  { key: 'accounting', text: 'Contabilidade' },
]

function PricingMain() {
  const [activeTab, setActiveTab] = useState(0);
  const [currentApp, setCurrentApp] = useState([]);
  useMemo(() => { setCurrentApp(appsPricings[`${activeTab}`]) }, [activeTab])
  const handleTabType = (event: any, newValue: any) => setActiveTab(newValue);

  return (
    <MKBox component="section" py={{ xs: 0, md: 12 }} sx={{ backgroundColor: '#EEE' }} borderRadius={15}>
      <Container>
        <Grid container item xs={12} justifyContent="center" md={8} mx="auto" textAlign="center">
          <MKTypography variant="h3">Escolha o melhor plano para o seu negócio</MKTypography>
          <MKTypography variant="body2" color="text">
            entendemos que cada negócio é único. É por isso que oferecemos uma variedade de opções de pacotes ERP para atender às necessidades específicas da sua organização. Nossas soluções são projetadas para impulsionar a eficiência, otimizar processos e aumentar a produtividade.
          </MKTypography>
        </Grid>

        <Grid
          container
          item
          xs={12}
          md={10}
          lg={8}
          justifyContent="center"
          textAlign="center"
          mx="auto"
          mt={6}
        >
          <AppBar position="static">
            <Tabs value={activeTab} onChange={handleTabType}>
              {appsHubs?.map(({ key, text }: any) =>
                <Tab
                  id={key}
                  label={
                    <MKBox py={0.5} px={2} color="inherit">
                      {text}
                    </MKBox>
                  }
                />)}

            </Tabs>
          </AppBar>
        </Grid>
        <Grid container alignItems={'center'} justifyContent={'center'} spacing={3} mt={6}>
          {currentApp?.filter(({ active }: any) => active)?.map((pricing: any) =>
            <Grid item xs={12} sm={6} lg={3}>
              <PricingCards
                {...pricing}
                app={appsHubs[activeTab]}
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default PricingMain;