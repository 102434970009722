import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { Grid, Typography } from '@mui/material';
import MKButton from 'components/MKButton';
import { CookiesProvider, useCookies } from 'react-cookie'
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export default function CockieDrawer() {
    const [state, setState] = React.useState(false);
    const [stati, setStati] = React.useState(false);
    const [cookies, setCookie] = useCookies(["accept"]);


    useMemo(() => {
        setTimeout(() =>
            !cookies.accept && stati && setState(true), 5000)
    }, [stati])

    const [searchParams, setSearchParams] = useSearchParams()
    useMemo(() => {
        let query = searchParams.get('bouce')
        if (query) {
            setState(false)
        }else{
            setStati(true)
        }
    }, [searchParams.get('bouce')])
    function handleAcceptCookies(accept: any) {
        setCookie("accept", accept, { path: "/" });
    }
    const toggleDrawer = (open: boolean) => setState(!state)


    return (

        <CookiesProvider>
            <React.Fragment key={'bottom'}>
                <Drawer
                    anchor={'bottom'}
                    open={state}
                    onClose={() => toggleDrawer(false)}
                >
                    <Box
                        sx={{ width: 'auto', maxHeight: 180 }}
                        role="presentation"
                        onClick={() => toggleDrawer(false)}
                        onKeyDown={() => toggleDrawer(false)}
                    >
                        <Grid container justifyContent={'center'} py={2}>
                            <Grid item sm={6} justifyContent={'center'} >
                                <Typography variant='h5'>
                                    Esse website utiliza cookies
                                </Typography>
                                <Typography style={{ fontSize: 12 }} pb={1} alignContent={'justify'}>
                                    o Nova ERP utiliza cookies para personalizar conteúdo e anúncios, fornecer recursos de mídia social e analisar nosso tráfego.

                                    Também compartilhamos informações sobre o uso do nosso site com nossos parceiros de mídia social, publicidade e análise, que podem combiná-las com outras informações que você forneceu a eles ou que eles coletaram do uso de seus serviços.

                                </Typography>
                                <MKButton variant={'gradient'} color={'info'} onClick={() => handleAcceptCookies(true)}>
                                    Aceitar os Cockies
                                </MKButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Drawer>
            </React.Fragment>
        </CookiesProvider>);
}
