/*
=========================================================
* Nova erp React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Hiperbite (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Nova erp React components
import MKBox from "components/MKBox";

// Nova erp React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import RequestDemoCard from "pages/components/RequestDemo/RequestDemoCard";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="shopping_basket"
                    title="Rastreamento de Inventário"
                    description="Permite acompanhar a quantidade disponível de cada item no stock"
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="account_tree"
                    title="Integração com Outros Sistemas"
                    description="Pode se integrar a sistemas de contabilidade, vendas, compras e outros, facilitando a troca de informações"
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="apps"
                    title="Múltiplos Locais de Armazenamento"
                    description="Permite gerenciar estocks em diferentes locais ou filiais"
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="description"
                    title="Histórico de Movimentações"
                    description="Registra as entradas, saídas e transferências de produtos, criando um histórico detalhado"
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <RequestDemoCard />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
