import { ErrorMessage } from '@hookform/error-message';
import { zodResolver } from '@hookform/resolvers/zod';
import { Close } from '@mui/icons-material';
import { Alert, CircularProgress, Container, Grid, Snackbar } from '@mui/material';
import MKBox from 'components/MKBox';
import MKButton from 'components/MKButton';
import MKInput from 'components/MKInput';
import MKTypography from 'components/MKTypography';
import React, { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useApi } from 'services/apiSlice';
import commonsEndpoints from 'services/endpoints/commons.endpoints';
import { z } from 'zod';
import Swal from 'sweetalert2'

const schema = z.object({
    name: z.string().min(5),
    companyName: z.string().min(5),
    email: z.string().email(),
    telephone: z.string(),
});

export default function StartBuyForm({ handleClose, children, data: packageData }: any) {
    const navigate = useNavigate()

    const [show, setShow] = useState(false)
    const { data, resolve, loading, error } = useApi({
        service: commonsEndpoints.clientRequest.create
    })
    const { register, reset, formState: { errors }, watch, handleSubmit } = useForm({
        resolver: zodResolver(schema)
    });
    const
        { app,
            title,
            description,
            price,

            specifications
        }
            = packageData
    useMemo(() => {
        if (data?.date) {
            Swal.fire({
                icon: "success",
                title: 'Pedido enviado',
                text: 'Pedido de adesão registado com sucesso! A nossa area comercial entrará em contacto em seguida '
              });
            reset()
        }
    }, [data])


    const onSubmit = async (data: any) => {
        console.log(data);
        const newData = {
            type: 5,//DEMO REQUEST
            message: JSON.stringify({ ...packageData, ...data }),
            client: {
                email: "default@nova.ao",
                type: 0,
                country: "AO",
                ...data
            }
        }

        resolve(newData);

    };

    return (
        <MKBox component="section" py={{ xs: 0, lg: 6 }}>
            <Container>
                <Grid container item>
                    <MKBox
                        width="100%"
                        bgColor="white"
                        borderRadius="xl"
                        shadow="xl"
                        mb={6}
                        sx={{ overflow: "hidden" }}
                    >
                        <Grid container spacing={2}>
                            <Grid
                                item
                                xs={12}
                                lg={5}
                                position="relative"
                                px={0}
                            >
                                <MKBox
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    width="100%"
                                    height="100%"
                                    style={{ background: "#333" }}
                                >
                                    <MKBox py={6} pr={6} pl={{ xs: 6, sm: 12 }} my="auto">
                                        <MKTypography variant="h3" color="white" mb={1}>
                                            Aderir ao pacote {title} da Solução {app?.text} do Nosso Software ERP
                                        </MKTypography>
                                        {children}

                                    </MKBox>
                                </MKBox>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                                <MKBox component="form" p={2} onSubmit={handleSubmit(onSubmit)}>

                                    <MKButton style={{ float: 'right' }} onClick={handleClose}>
                                        <Close />
                                    </MKButton>
                                    <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                                        <MKTypography variant="h2" mb={1}>
                                            Preencha o formulário para Adesão
                                        </MKTypography>
                                        <MKTypography variant="body1" color="text" mb={2}>

                                        </MKTypography>
                                    </MKBox>
                                    <MKBox pt={0.5} pb={3} px={3}>
                                        <Grid container>
                                            <Grid item xs={12} pr={1} mb={6}>
                                                <MKInput
                                                    label="Seu nome"
                                                    placeholder="Seu Nome completo"
                                                    fullWidth
                                                    {...register("name", { required: "Este campo é obrigatorio." })}
                                                />
                                                <MKBox color="#F00" fontSize={12}><ErrorMessage errors={errors} name="name" /></MKBox>
                                            </Grid>
                                            <Grid item xs={12} pr={1} mb={6}>
                                                <MKInput
                                                    label="Nome da sua empresa"
                                                    placeholder="Nome da sua empresa"
                                                    fullWidth
                                                    {...register("companyName", { required: "Este campo é obrigatorio." })}
                                                />
                                                <MKBox color="#F00" fontSize={12}><ErrorMessage errors={errors} name="companyName" /></MKBox>
                                            </Grid>
                                            <Grid item xs={12} pr={1} mb={6}>
                                                <MKInput
                                                    label="E-mail"
                                                    placeholder="E-mail"
                                                    fullWidth
                                                    {...register("email", { required: "Este campo é obrigatorio." })}
                                                />
                                                <MKBox color="#F00" fontSize={12}><ErrorMessage errors={errors} name="email" /></MKBox>
                                            </Grid>
                                            <Grid item xs={12} pr={1} mb={6}>
                                                <MKInput
                                                    label="Telefone"
                                                    placeholder="Número de telefone"
                                                    fullWidth
                                                    {...register("telephone", { required: "Este campo é obrigatorio." })}
                                                />
                                                <MKBox color="#F00" fontSize={12}><ErrorMessage errors={errors} name="telephone" /></MKBox>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            md={6}
                                            justifyContent="flex-end"
                                            textAlign="right"
                                            ml="auto"
                                        >
                                            <MKButton variant={'gradient'} color={"info"} type="submit" disabled={loading}>
                                                {loading ? <><CircularProgress /> {' '}
                                                    Enviando
                                                </> :
                                                    <>
                                                        Enviar pedido
                                                    </>}
                                            </MKButton>
                                        </Grid>
                                    </MKBox>
                                </MKBox>
                            </Grid>
                        </Grid>
                    </MKBox>
                </Grid>
            </Container>

            <Snackbar
                sx={{ maxWidth: 600, width: '100%' }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={show}
                autoHideDuration={10000}
                onClose={() => setShow(false)}
                onClick={handleClose}

            >
                <Alert sx={{ maxWidth: 600, width: '100%' }} variant="filled" severity="success">Pedido de adesão registado com sucesso! o nosso operador entrará em contacto </Alert>

            </Snackbar>
        </MKBox>

    );
}