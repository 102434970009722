/*
=========================================================
* Nova erp React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Hiperbite (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Carousel from "react-material-ui-carousel";

// Nova erp React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";
import Item from "components/Item";

// Nova erp React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
import Counters from "pages/Presentation/sections/Counters";
import Information from "pages/Presentation/sections/Information";
import DesignBlocks from "pages/Presentation/sections/DesignBlocks";
import Pages from "pages/Presentation/sections/Pages";
import Testimonials from "pages/Presentation/sections/Testimonials";
import Download from "pages/Presentation/sections/Download";

// Presentation page components
import BuiltByDevelopers from "pages/Presentation/components/BuiltByDevelopers";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-presentation.jpg";
import Clients from "./sections/Clients";
import { useEffect, useState } from "react";
import CockieDrawer from "pages/components/CockieDrawer";

function Presentation() {
  const items = [
    {
      description:
        "Transformamos negócios em sucesso. Descubra o parceiro ideal para impulsionar o seu.",
    },
    {
      description:
        "Do pequeno ao grande, somos especialistas em potencializar o seu negócio. Descubra como podemos ser o seu parceiro de sucesso.",
    },
    {
      description:
        "Conquiste novos horizontes com o parceiro ideal para alavancar o seu negócio, independentemente do seu tamanho ou setor.",
    },
    {
      description:
        "Construímos pontes para o sucesso dos negócios. Descubra como ser o seu parceiro estratégico para crescimento e inovação.",
    },
    {
      description:
        "Não importa o seu ramo, estamos prontos para impulsionar o seu negócio. Descubra por que somos o parceiro ideal para o seu sucesso.",
    },
  ];

  const [timer, setTimer] = useState(0);

  setInterval(() => setTimer(timer + 1), 10000);
  useEffect(() => {
    if (timer > 1) {
    }
  }, [timer]);
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://demo.nova.ao",
          label: "Comece a versão de Demostração",
          color: "info",
        }}
        sticky
      />
      <MKBox
        minHeight="100vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            p={{xs:2, md:10}}
            item
            xs={12}
            lg={10}
            justifyContent="left"
            mx="auto"
            sx={{ background: "rgba(0,0,0,.5)" }}
          >
            <Grid item md={6}>
              <MKTypography variant="h1" color="white">
                Nova erp <br />
                Solução de Gestão empresarial nas núvens{" "}
              </MKTypography>
            </Grid>
            <Grid item md={6} xs={12} sx={{ color: "#FFF"}}>
              <Carousel>
                {items.map((item, i) => (
                  <Item key={i} item={item} />
                ))}
              </Carousel>
            </Grid>

          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }: any) =>
            rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }: any) => xxl,
        }}
      >
        <Counters />
        <Information />
        <DesignBlocks />
        <Pages />
        <Container sx={{ mt: 6 }}>
          <BuiltByDevelopers />
        </Container>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                variant="gradient"
                color="info"
                icon="flag"
                title="Tutoriais em Vídeo"
                description="Vídeos explicativos para demonstrar o uso eficaz do software em cenários do mundo real."
                action={{
                  type: "internal",
                  route: "/suport/help-center#tutorials",
                  label: "Veja agora",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon="precision_manufacturing"
                title="FAQs e Solução de Problemas"
                description="Uma lista de perguntas frequentes e soluções para possíveis problemas que possam surgir."
                action={{
                  type: "internal",
                  route: "/suport/faqs",
                  label: "Veja agora",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon="apps"
                title="Guia do Usuário"
                description="Um manual completo para orientar os usuários sobre como utilizar todas as funcionalidades do sistema."
                action={{
                  type: "external",
                  route: "/suport/help-center",
                  label: "Ver agora",
                }}
              />
            </Grid>
          </Grid>
        </Container>
        <Testimonials />

        <Clients />
        <Download />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <CockieDrawer />
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;
