import { Container, Grid, Icon, Stack } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function AllYouNeed() {
    return (
        <MKBox component="section" py={{ xs: 3, md: 12 }}>
            <Container>
                <Grid container alignItems="center">
                    <Grid item xs={12} lg={5}>
                        <MKTypography variant="h3" my={1}>
                            A Nova erp tem tudo o que você precisa, incluído em todos os planos
                        </MKTypography>
                        <MKTypography variant="body2" color="text" mb={2}>

                            A Nova erp oferece o suporte completo para seus funcionários. Do básico em aprimorar seu fluxo operacional até extrair o melhor de seus colaboradores. Além disso, oferecemos flexibilidade para você criar o plano que melhor atenda às suas necessidades.
                        </MKTypography>
                        <MKTypography
                            component="a"
                            href="#"
                            variant="body2"
                            color="info"
                            fontWeight="regular"
                            sx={{
                                width: "max-content",
                                display: "flex",
                                alignItems: "center",

                                "& .material-icons-round": {
                                    fontSize: "1.125rem",
                                    transform: "translateX(3px)",
                                    transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                                },

                                "&:hover .material-icons-round, &:focus .material-icons-round": {
                                    transform: "translateX(6px)",
                                },
                            }}
                        >
                            Saiba mais
                            <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                        </MKTypography>
                    </Grid>
                    <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
                        <Stack>
                            <MKBox display="flex" alignItems="center" p={2}>
                                <MKBox
                                    width="3rem"
                                    height="3rem"
                                    variant="gradient"
                                    bgColor="info"
                                    color="white"
                                    coloredShadow="info"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    borderRadius="xl"
                                >
                                    <Icon fontSize="small">mediation</Icon>
                                </MKBox>
                                <MKTypography variant="body2" color="text" pl={2}>
                                    Suporte e assintencia técnica grátis
                                    <br />
                                    Ao escolher os nossos pacotes, oferecemos suporte e assistência técnica grátis
                                </MKTypography>
                            </MKBox>
                            <MKBox display="flex" alignItems="center" p={2}>
                                <MKBox
                                    width="3rem"
                                    height="3rem"
                                    variant="gradient"
                                    bgColor="info"
                                    color="white"
                                    coloredShadow="info"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    borderRadius="xl"
                                >
                                    <Icon fontSize="small">settings_overscan</Icon>
                                </MKBox>
                                <MKTypography variant="body2" color="text" pl={2} sty>
                                    Formação, treinamento e materiais de apoio
                                    <br />
                                    valorizamos o desenvolvimento e capacitação contínua dos nossos clientes,
                                </MKTypography>
                            </MKBox>
                            <MKBox display="flex" alignItems="center" p={2}>
                                <MKBox
                                    width="3rem"
                                    height="3rem"
                                    variant="gradient"
                                    bgColor="info"
                                    color="white"
                                    coloredShadow="info"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    borderRadius="xl"
                                >
                                    <Icon fontSize="small">token</Icon>
                                </MKBox>
                                <MKTypography variant="body2" color="text" pl={2}>
                                    Manuntenção e actualziação continua.
                                    <br />
                                    comprometemo-nos para garantir que você tenha a melhor experiência possível
                                </MKTypography>
                            </MKBox>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
    );
}

export default AllYouNeed;