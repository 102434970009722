import * as React from 'react';
import Box from '@mui/material/Box';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ArrowRight from '@mui/icons-material/ArrowRight';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Home from '@mui/icons-material/Home';
import Settings from '@mui/icons-material/Settings';
import People from '@mui/icons-material/People';
import PermMedia from '@mui/icons-material/PermMedia';
import Dns from '@mui/icons-material/Dns';
import Public from '@mui/icons-material/Public';
import MKInput from 'components/MKInput';
import { InputAdornment } from '@mui/material';
import SearchInput from './SearchInput';
import ItemList from '../components/ItemList';

const data = [
  { icon: <People />, label: 'Authentication' },
  { icon: <Dns />, label: 'Database' },
  { icon: <PermMedia />, label: 'Storage' },
  { icon: <Public />, label: 'Hosting' },
];

const FireNav = styled(List)<{ component?: React.ElementType }>({
  '& .MuiListItemButton-root': {
    paddingLeft: 24,
    paddingRight: 24,
  },
  '& .MuiListItemIcon-root': {
    minWidth: 0,
    marginRight: 16,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 20,
  },
});

export default function ItemBarList({ item: currentItem, setItem }: any) {
  const [open, setOpen] = React.useState(true);
  React.useMemo(() => businessData?.length > 0 && setItem(businessData[0]), [businessData])
  return (
    <Box sx={{ display: 'flex' }}>
      <ThemeProvider
        theme={createTheme({
          components: {
            MuiListItemButton: {
              defaultProps: {
                disableTouchRipple: true,
              },
            },
          },
          palette: {
            mode: 'dark',
            primary: { main: 'rgb(102, 157, 246)' },
            background: { paper: 'rgb(5, 30, 52)' },
          },
        })}
      >
        <Paper elevation={0} sx={{ maxWidth: 356, width: '100%' }}>
          <FireNav component="nav" disablePadding>
            <ListItemButton component="a" href="#customized-list">
              <ListItemIcon sx={{ fontSize: 20 }}>🔥</ListItemIcon>
              <ListItemText
                sx={{ my: 0 }}
                primary="Tópicos"
                primaryTypographyProps={{
                  fontSize: 20,
                  fontWeight: 'medium',
                  letterSpacing: 0,
                }}
              />
            </ListItemButton>
            <Divider />
            <ListItem component="div" disablePadding>
              <SearchInput />
            </ListItem>
            <Divider />
            <Box
              sx={{
                bgcolor: open ? 'rgba(71, 98, 130, 0.2)' : null,
                pb: open ? 2 : 0,
              }}
            >
              <ListItemButton
                alignItems="flex-start"
                onClick={() => setOpen(!open)}
                sx={{
                  px: 3,
                  pt: 2.5,
                  pb: open ? 0 : 2.5,
                  '&:hover, &:focus': { '& svg': { opacity: open ? 1 : 0 } },
                }}
              >
                <ListItemText
                  primary=""
                  primaryTypographyProps={{
                    fontSize: 15,
                    fontWeight: 'medium',
                    lineHeight: '20px',
                    mb: '2px',
                  }}
                  secondary={businessData.map(({ title }: any) => title).join(', ')}
                  secondaryTypographyProps={{
                    noWrap: true,
                    fontSize: 12,
                    lineHeight: '16px',
                    color: open ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0.5)',
                  }}
                  sx={{ my: 0 }}
                />
                <KeyboardArrowDown
                  sx={{
                    mr: -1,
                    opacity: 0,
                    transform: open ? 'rotate(-180deg)' : 'rotate(0)',
                    transition: '0.2s',
                  }}
                />
              </ListItemButton>
              {open &&
                businessData.map((item: any, id: number) => (

                  <ItemList item={item} setItem={setItem} id={id} />
                ))}
            </Box>
          </FireNav>
        </Paper>
      </ThemeProvider>
    </Box>
  );
}


const items = [
  {
    title: "Cabeleireiro e Estética",
    text: "Para salões de beleza e clínicas de estética, a gestão eficaz de agendamentos e controle de stock de produtos de beleza são fundamentais. Um sistema ERP permite agendar serviços de forma eficiente e manter o stock de produtos em dia. Além disso, a faturação automática agiliza o processo de cobrança."
  },
  {
    title: "Oficinas Mecânicas",
    text: "Em uma oficina mecânica, a gestão de agendamentos e controle de peças e materiais são essenciais. Um sistema ERP facilita o agendamento de serviços, permite o controle preciso das peças em stock e simplifica o processo de faturação de reparos."
  },
  {
    title: "Restaurante",
    text: "A gestão de um restaurante envolve reservas, controle de inventário de alimentos e bebidas, faturação de pedidos e gerenciamento de equipe. Com um sistema ERP, é possível otimizar esses processos, proporcionando uma experiência mais eficiente e agradável para os clientes."
  },
  {
    title: "Supermercado",
    text: "Em um supermercado, a gestão eficiente de inventário é crucial para atender às demandas dos clientes. Com um sistema ERP integrado, você pode monitorar em tempo real os níveis de stock de produtos, facilitando a reposição e evitando a falta de itens essenciais. Além disso, a faturação automatizada agiliza o processo de pagamento, proporcionando uma experiência de compra mais rápida e eficiente para os clientes. A análise de dados integrada também pode fornecer insights valiosos sobre o comportamento do consumidor e as preferências de compra."
  },
  {
    title: "Hotelaria",
    text: "Na indústria hoteleira, a gestão eficaz de reservas, quartos e serviços é fundamental para proporcionar uma estadia memorável aos hóspedes. Com um sistema ERP, é possível agilizar a administração de reservas, permitindo um processo de check-in e check-out mais rápido e eficiente. Além disso, o controle de inventário de produtos de higiene e serviços de quarto garante que todos os itens estejam prontamente disponíveis para atender às necessidades dos hóspedes. A gestão de recursos humanos também é simplificada, assegurando que a equipe esteja bem treinada e escalada para oferecer um serviço de alta qualidade."
  },
  {
    title: "Fábrica",
    text: "Em uma fábrica, a eficiência na produção e no controle de stock de matéria-prima é crucial para garantir a qualidade dos produtos e atender à demanda do mercado. Um sistema ERP proporciona uma visão abrangente da produção em tempo real, permitindo otimizar processos e minimizar desperdícios. Além disso, o controle de inventário de matérias-primas garante que a fábrica tenha sempre os insumos necessários disponíveis. A faturação automatizada agiliza o processo de venda dos produtos fabricados, contribuindo para a rentabilidade da empresa."
  },
  {
    title: "Armarinho e Loja",
    text: "Para um armarinho ou loja, a gestão eficaz de inventário e vendas é essencial para atender às demandas dos clientes de forma eficiente. Com um sistema ERP, você pode monitorar em tempo real os níveis de stock de produtos, evitando a falta de itens populares e garantindo a disponibilidade de produtos sazonais. Além disso, a faturação automatizada simplifica o processo de pagamento, proporcionando uma experiência de compra mais rápida e conveniente para os clientes. A gestão de recursos humanos também é facilitada, garantindo que a equipe esteja bem treinada e pronta para fornecer um atendimento excepcional."
  },
  {
    title: "Tecnologia e Informática",
    text: "Em empresas de tecnologia e informática, a gestão eficiente de projetos e o controle de inventário de hardware/software são cruciais para o sucesso do negócio. Um sistema ERP proporciona uma visão abrangente de todos os projetos em andamento, permitindo alocar recursos de forma eficaz e garantir que os prazos sejam cumpridos. Além disso, o controle de inventário de hardware/software garante que a empresa tenha os recursos necessários para atender às demandas dos clientes. A faturação automatizada agiliza o processo de cobrança, contribuindo para a saúde financeira da empresa."
  },
  {
    title: "Alimentação e Bebidas",
    text: "Na indústria de alimentação e bebidas, a gestão eficiente de pedidos e o controle de inventário de alimentos e bebidas são fundamentais para garantir a satisfação dos clientes. Com um sistema ERP, é possível otimizar o processo de pedidos, garantindo que os clientes recebam seus produtos de forma rápida e precisa. Além disso, o controle de inventário de alimentos e bebidas garante que a empresa tenha os produtos necessários para atender à demanda. A faturação automatizada simplifica o processo de pagamento, proporcionando uma experiência de compra mais rápida e eficiente para os clientes."
  }
];
const
  businessData = [
    {
      title: "Contabilidade",
      text: "Em um negócio de lavandaria, a eficiência operacional é essencial. Com um sistema ERP integrado, você pode agilizar agendamentos, rastrear o ciclo de vida das peças, gerenciar funcionários e controlar o stock de produtos de limpeza. Além disso, a faturação automatizada torna o processo de cobrança de serviços simples e eficaz.",
      items,

    },
    {
      title: "Recursos Humanos",
      text: "Para um cinema, a gestão eficiente de bilheteria e de alimentos e bebidas é crucial. Um sistema ERP proporciona controle total sobre as vendas de ingressos, permitindo agendamento fácil de sessões e rastreamento de assentos ocupados. Além disso, a gestão de inventário de alimentos e bebidas garante que os itens estejam sempre disponíveis para os clientes.",
      items,
    },
    {
      title: "Vendas",
      text: "No setor de saúde, um sistema ERP facilita a administração de recursos humanos, otimizando escalas de turnos e gerenciando a folha de pagamento. Além disso, o controle de stock de suprimentos médicos garante que a clínica ou hospital tenha sempre os materiais necessários à disposição.",
      items,

    },
    {
      title: "Stock",
      text: "Para uma clínica, a gestão de agendamentos, históricos de pacientes, faturação de consultas e procedimentos, bem como a administração dos recursos humanos, são fundamentais. Um sistema ERP ajuda a agendar serviços de forma eficiente e a manter o stock de produtos em dia. Além disso, a faturação automática agiliza o processo de cobrança.",
      items,
    },

  ]


