/*
=========================================================
* Nova erp React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Hiperbite (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";

// Nova erp React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import bgImage from "assets/images/shapes/waves-white.svg";
import image0 from "assets/images/logos/cert/entity_0.png";
import image1 from "assets/images/logos/cert/entity_1.png";
import image2 from "assets/images/logos/cert/entity_2.png";
import image3 from "assets/images/logos/cert/entity_3.png";

const images =[image0,image1,image2,image3]
function Download() {

  return (
    <MKBox component="section" py={{ xs: 0, sm: 12 }}>
      <MKBox
        variant="gradient"
        bgColor="dark"
        position="relative"
        borderRadius="xl"
        sx={{ overflow: "hidden" }}
      >
        <MKBox
          component="img"
          src={bgImage}
          alt="pattern-lines"
          position="absolute"
          top={0}
          left={0}
          width="100%"
          zIndex={1}
          opacity={0.2}
        />
        <Container sx={{ position: "relative", zIndex: 2, py: 12 }}>
          <Grid container>
            <Grid item xs={12} md={6}  justifyContent="center" textAlign="center">
              <Grid container spacing={5} justifyContent={'center'}>
                {images.map((image: number) =>
                  <Grid item xs={12} md={4} justifyContent="center" textAlign="center">
                    <MKBox
                      component="img"
                      src={image}
                      alt={name}
                      width="100%"
                      my="auto"
                      opacity={1}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} justifyContent="center" textAlign="center">
              <MKTypography variant="h3" color="white">
                Software validado e Certificado
              </MKTypography>
              <MKTypography variant="h3" color="white" mb={1}>
              </MKTypography>
              <MKTypography variant="body2" color="white" mb={6}>
                É com orgulho que anunciamos que nosso software recebeu a validação e certificação das entidades reguladoras pertinentes. Isso significa que nossa solução atende aos mais rigorosos padrões e regulamentos do setor, garantindo a conformidade e a segurança dos dados para nossos clientes.
              </MKTypography>
              <MKButton
                variant="gradient"
                color="info"
                size="large"
                component="a"
                href="suport/help-center"
                sx={{ mb: 2 }}
              >
                Saber mais
              </MKButton>
            </Grid>

          </Grid>
        </Container>
      </MKBox>
    </MKBox >
  );
}

export default Download;
