/*
=========================================================
* Nova erp React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Hiperbite (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Nova erp React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Nova erp React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Information from "./sections/Information";
import Modules from "./sections/Modules";


import Newsletter from "pages/components/Newsletter";
import Faqs from "pages/components/Faqs";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/industria.jpg";
import RequestDemo from "pages/components/RequestDemo/Index";
import Featuring from "pages/components/Featuring";

function Stock() {

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: ".",
          label: "free download",
          color: "default",
        }}
        transparent
        light
      />
      <MKBox
        minHeight="85vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }: any) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            xs={12}
            lg={12}
          >
            <Grid
              container
              item
              xs={6}
              lg={6}
              justifyContent="left"
              alignItems="center"
              flexDirection="column"
              sx={{ mx: "auto", textAlign: "left" }}
            >
              <MKTypography
                variant="h1"
                color="white"
                sx={({ breakpoints, typography: { size } }: any) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Software de Gestão de Stock
              </MKTypography>
              <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
                A Nova erp permite fornecer funcionalidades para monitorar, organizar e otimizar o stock da sua empresa de forma eficiente!
              </MKTypography>

              <RequestDemo text={'Solicitar versão de Demostração'} />
              <MKTypography variant="caption" color="white" mt={8} mb={1}>
                Mais de 40 empresas em todo o mundo confiam na Nova erp
              </MKTypography>
              <MKBox display="flex" justifyContent="center" alignItems="center">
                {footerRoutes.socials.map(({ icon, link }: any) =>
                  <MKTypography component="a" variant="body1" color="white" href={link} mr={3}>
                    {icon}
                  </MKTypography>
                )}
              </MKBox>
            </Grid>
            <Grid
              container
              item
              xs={6}
              lg={6}
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              sx={{ mx: "auto", textAlign: "left" }}
            >
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }: any) => xxl,
        }}
      >
        <Information />
        <Modules />
        <Newsletter />
        <Faqs />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Stock;
