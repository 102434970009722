import { Controller } from "react-hook-form";

import Select from 'react-select'
//import './MySelect.css'
const MySelect = ({ name, placeholder, options, ...rest }: any) => {
  const { control, value: va, changing, readonly, loading = false } = rest;
  return (
    <div className="react-select-2" style={{width:"100%"}}>
      {control  ? (
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value: v, name, ref } }: any) => (<>
            {options && <Select options={options}
              className="MuiInputBase-input css-10ukfau-MuiInputBase-input"
              name={name}
              isDisabled={readonly}
              isLoading={loading}
              isClearable={!readonly}
              defaultValue={options?.find((option: any) => String(option.value) == String(v ?? va))}
              onChange={(e: any) => { (changing && changing(e?.value)); onChange(e?.value) }}
            />}
          </>
          )}
        />
      ) : (<>
        <select
          readonly={true}
          name={name}
          placeholder={placeholder}
          className="MuiInputBase-input css-10ukfau-MuiInputBase-input"
          {...rest}
        >
          {options?.map(({ value, label }: any, key: number) => (
            <option key={key} selected={value === va} value={value}>
              {label}
            </option>
          ))}
        </select></>
      )}
      
    </div>
  );
};

export default MySelect;
