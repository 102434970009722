import { Container, Grid, Typography } from '@mui/material'
import MKBox from 'components/MKBox'
import React, { useEffect, useMemo, useState } from 'react'
import ItemBarList from './ItemBarList'
import BusinessDetails from './BusinessDetails'


const BusinessDescriptions = () => {

    const [business, setBusiness] = useState<any>(0);

    return (
        <MKBox component="section" py={{ xs: 0, lg: 6 }}>
            <Container>
                <Typography variant="h1">Negocios</Typography>
                <Typography variant="body2" py={3}>
                    Um sistema ERP (Enterprise Resource Planning) que abrange os módulos de RH, faturação, gestão de stock e contabilidade é uma solução versátil e pode ser benéfica para uma ampla variedade de tipos de negócios
                </Typography>
                <Grid container>
                    <Grid item md={3}><ItemBarList business={business} setBusiness={setBusiness} /></Grid>
                    <Grid item md={9}><BusinessDetails business={business} /></Grid>
                </Grid>
            </Container>
        </MKBox>
    )
}

export default BusinessDescriptions
