import { ExpandLess, ExpandMore, Inbox, StarBorder } from '@mui/icons-material'
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import React from 'react'

const ItemList = ({ item,  setItem, id }: any) => {
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };
    return (
        <>
            <ListItemButton onClick={handleClick}
                key={item.title}
                sx={{ py: 2, minHeight: 32, color: 'rgba(255,255,255,.8)' }}
            >
                <ListItemIcon sx={{ color: 'inherit' }}>
                    <Inbox />
                </ListItemIcon>
                <ListItemText primary={item.title}
                    primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>

                <List component="div" disablePadding>
                    {item?.items?.map((i: any) =>
                        <ListItemButton style={{ marginLeft: 20, fontSize: 10 }} onClick={() => setItem({ ...i, topic: item, id })}>
                            <ListItemText primary={i.title} />
                        </ListItemButton>
                    )}
                </List>
            </Collapse>
        </>
    )
}

export default ItemList
