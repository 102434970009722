/*
=========================================================
* Nova erp React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Hiperbite (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const imagesPrefix =
"assets/images/screens/";

export default [
  {
    title: "Recursos Humanos",
    description: "Software de RH completo para valorizar pessoas, transforma processos complexos em tempo de qualidade para você e seus colaboradores. Impulsione sua empresa valorizando as pessoas!",
    items: [
      {
        image: `${imagesPrefix}rh_screenshot16.png`,
        name: "Cadastramento e Gestão de Carreira",
        count: 11,
      },
      {
        image: `${imagesPrefix}rh_screenshot3.png`,
        name: "Avaliação e desempenho",
        count: 10,
        route: "/sections/page-sections/page-headers",
      },
      {
        image: `${imagesPrefix}rh_screenshot4.png`,
        name: "Ausências, Ponto, Férias e Horas extras",
        count: 14,
        route: "/sections/page-sections/features",
      },
      /*{
        image: `${imagesPrefix}rh_screenshot5.png`,
        name: "Gestão de Remuneração e folhas de pagamento",
        count: 8,
      },
      {
        image: `${imagesPrefix}rh_screenshot6.png`,
        name: "Recrutamento, Seleção e Gestão de Talentos",
        count: 1,
      },
      {
        image: `${imagesPrefix}rh_screenshot7.png`,
        name: "Cadastramento e Gestão de Colaboradores",
        count: 11,
      },
      {
        image: `${imagesPrefix}rh_screenshot8.png`,
        name: "Portal do Colaborador e App mobile",
        count: 6,
      },*/
      
    ],
  },
  {
    title: "Vendas",
    description: "Software para gestão de Comércio e Vendas, simplifica a gestão de vendas e aumenta seus lucros. Descubra o caminho para o sucesso nas vendas!",
    items: [
      {
        image: `${imagesPrefix}rh_screenshot8.png`,
        name: "Emissão de Facturas, Factura proformas, Facturas Recibos, Recibos, Nota de crédito, de entrega e de recebimento",
        count: 3,
        route: "/sections/navigation/pagination",
      },
      {
        image: `${imagesPrefix}rh_screenshot8.png`,
        name: "SAF-T",
        count: 3,
        route: "/sections/navigation/pagination",
      },
      {
        image: `${imagesPrefix}rh_screenshot8.png`,
        name: "Gestão de Conta Corrente de clientes",
        count: 3,
        route: "/sections/navigation/pagination",
      },
    /*  {
        image: `${imagesPrefix}rh_screenshot8.png`,
        name: "POS",
        count: 4,
        route: "/sections/navigation/navbars",
      },
      {
        image: `${imagesPrefix}rh_screenshot8.png`,
        name: "Relatórios",
        count: 2,
        route: "/sections/navigation/nav-tabs",
      },
      {
        image: `${imagesPrefix}rh_screenshot8.png`,
        name: "SAF-T",
        count: 3,
        route: "/sections/navigation/pagination",
      },*/
    ],
  },
  {
    title: "Contabilidade",
    description: "Software de Contabilidade para análise financeira, permite fornecer informações precisas e relevantes sobre a situação financeira e o desempenho da sua empresa!",
    items: [
      {
        image: `${imagesPrefix}rh_screenshot8.png`,
        name: "Plano geral de conta",
        count: 6,
      },
      {
        image: `${imagesPrefix}rh_screenshot8.png`,
        name: "Registo de Transacções Financeiras",
        count: 8,
      },
      {
        image: `${imagesPrefix}rh_screenshot8.png`,
        name: "Demonstrações de Resultado de Exercicios(DRE)",
        count: 3,
        route: "/sections/input-areas/forms",
      },
      /*{
        image: `${imagesPrefix}rh_screenshot8.png`,
        name: "Controle de Activos e Passivos",
        count: 6,
        route: "/sections/input-areas/inputs",
      },
      {
        image: `${imagesPrefix}rh_screenshot8.png`,
        name: "Balancete Analítico e Sintético",
        count: 3,
        route: "/sections/input-areas/forms",
      },
      {
        image: `${imagesPrefix}rh_screenshot8.png`,
        name: "Conciliação Bancária",
        count: 6,
        route: "/sections/input-areas/inputs",
      },
      {
        image: `${imagesPrefix}rh_screenshot8.png`,
        name: "Fluxo de Caixa",
        count: 6,
        route: "${imagesPrefix}rh_screenshot8.png",
      },
      {
        image: `${imagesPrefix}rh_screenshot8.png`,
        name: "Facturamento e Cobrança",
        count: 6,
        route: "/sections/input-areas/inputs",
      },*/
    ],
  },
  {
    title: "Stock",
    description: "Software de Gestão de Inventário, permite fornecer funcionalidades para monitorar, organizar e otimizar o stock da sua empresa de forma eficiente!",
    items: [
      {
        image: `${imagesPrefix}rh_screenshot8.png`,
        name: "Registo de Produtos",
        count: 4,
        route: "/sections/attention-catchers/alerts",
      },
      {
        image: `${imagesPrefix}rh_screenshot8.png`,
        name: "Gerenciamento de Fornecedores",
        count: 3,
      },
      {
        image: `${imagesPrefix}rh_screenshot8.png`,
        name: "Controle de Movimentação de Stock",
        count: 2,
        route: "/sections/attention-catchers/tooltips-popovers",
      },
      /*{
        image: `${imagesPrefix}rh_screenshot8.png`,
        name: "Gestão de Inventário",
        count: 5,
        route: "/sections/attention-catchers/modals",
      },
      {
        image: `${imagesPrefix}rh_screenshot8.png`,
        name: "Controle de Quantidade em Stock",
        count: 5,
        route: "/sections/attention-catchers/modals",
      },
      {
        image: `${imagesPrefix}rh_screenshot8.png`,
        name: "Gestão Multilocal",
        count: 5,
        route: "/sections/attention-catchers/modals",
      },*/
    ],
  },
  
];
