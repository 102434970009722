/*
=========================================================
* Nova erp React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Hiperbite (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Nova erp React components
import MKBox from "components/MKBox";

// Nova erp React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import RequestDemoCard from "pages/components/RequestDemo/RequestDemoCard";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="receipt_long"
                    title="Faturação"
                    description="Emissão de Facturas, Factura Proformas, Facturas Recibo, Recibos, Nota de crédito, Nota de entrega, Nota de recebimento, SAF-T, Conta corrente de clientes"
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="settings_accessibility"
                    title="Controle de Acesso"
                    description="Possibilita a configuração de permissões de acesso para diferentes usuários, garantindo que apenas as pessoas autorizadas tenham acesso às informações financeiras"
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="event_note"
                    title="Relatórios"
                    description="Oferece a capacidade de criar relatórios financeiros, como balanços, demonstrações de resultados e relatórios de vendas."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="open_with"
                    title="Personalização"
                    description="Permite personalizar modelos de faturas, recibos e relatórios para atender às necessidades específicas da sua empresa"
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <RequestDemoCard/>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
