/*
=========================================================
* Nova erp React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Hiperbite (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Nova erp React components
import MKBox from "components/MKBox";

// Nova erp React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Author page sections
import Profile from "pages/Prices/Prices/sections/Profile";
import Posts from "pages/Prices/Prices/sections/Posts";
import Contact from "pages/Prices/Prices/sections/Contact";

// Routes
import routes from "routes";

// Images
import bgImage from "assets/images/city-profile.jpg";
import AllYouNeed from "./sections/AllYouNeed";
import MKTypography from "components/MKTypography";
import { Container, Grid } from "@mui/material";
import MKInput from "components/MKInput";
import MKProgress from "components/MKProgress";
import MKButton from "components/MKButton";
import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";
import { useCookies } from "react-cookie";
import PricingWizard from "./sections/PricingWizard";
import PricingCard from "./components/PricingCard";

function HubCalculator() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-react",
          label: "free download",
          color: "info",
        }}
        transparent
        light
      />
      <MKBox bgColor="white">
        <MKBox

          minHeight="15rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }: any) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        >
        </MKBox>
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }: any) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }: any) => xxl,
          }}
        >
          <HubResult />
          <AllYouNeed />
        </Card>
        <PricingWizard initialData={{}} />
      </MKBox>

      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}


const HubResult = () => {

  const [{ hubCalculator: {
    businessId,
    final,
    companySize,
    companyAmount,
    name,
    email
  } }, setCookie] = useCookies(["hubCalculator"]);

  const princing =
  {
    active: true,
    color: "dark",
    title: "Hub empresa",
    description: (companySize ===0 ? '∞' : companySize) + " Funcionários",
    price: { value: final * 56250.00, type: "mês" },
    action: { type: "internal", route: "/", label: "Adquira já" },
    specifications: [
      'Gestão de Recurso Humanos ' + ['Pro', 'Flex'][companySize > 10 ? 1 : 0],
      'Gestão de Vendas ' + ['Basic', 'Pro'][companyAmount === 0 ? 1 : 0],
      'Gestão de Contabilidade ',
      'Gestão de Stock Basico',
    ]
  }
  return <><pre>{ }</pre>

    <MKBox component="section" py={{ xs: 0, md: 12 }} sx={{ backgroundColor: '#EEE' }} borderRadius={15}>
      <Container>
        <Grid container item xs={12} justifyContent="center" md={8} mx="auto" textAlign="center">
          <MKTypography variant="h3">Escolha o melhor plano para o seu negócio</MKTypography>
          <MKTypography variant="body2" color="text">
            entendemos que cada negócio é único. É por isso que oferecemos uma variedade de opções de pacotes ERP para atender às necessidades específicas da sua organização. Nossas soluções são projetadas para impulsionar a eficiência, otimizar processos e aumentar a produtividade.
          </MKTypography>
        </Grid>

        <Grid
          container
          item
          xs={12}
          md={10}
          lg={8}
          justifyContent="center"
          textAlign="center"
          mx="auto"
          mt={6}
        >

        </Grid>
        <Grid container alignItems={'center'} justifyContent={'center'} spacing={3} mt={6}>

          <Grid item xs={12} sm={6} lg={3}>
            <PricingCard
              {...princing}
            />
          </Grid>

        </Grid>
      </Container>
    </MKBox>
  </>
}
export default HubCalculator;
