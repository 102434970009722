/*
=========================================================
* Nova erp React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Hiperbite (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Nova erp React components
import MKBox from "components/MKBox";

// Nova erp React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import RequestDemoCard from "pages/components/RequestDemo/RequestDemoCard";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="public"
                    title="Auditoria"
                    description="Permite avaliar a precisão e confiabilidade das demonstrações financeiras de uma empresa!"
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="payments"
                    title="Contabilidade Financeira"
                    description="Permite preparar relatórios financeiros para usuários externos, como acionistas, investidores, credores e reguladores!"
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="apps"
                    title="Contabilidade Gerencial"
                    description="Permite fornecer informações para a tomada de decisões internas e a gestão eficaz da empresa!"
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="3p"
                    title="Contabilidade de Custos"
                    description="Permite analisar e controlar os custos envolvidos na produção de bens e serviços.
                    Principais Documentos: Relatórios de custos, análise de margens de lucro!"
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <RequestDemoCard/>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
