import { ErrorMessage } from '@hookform/error-message'
import { zodResolver } from '@hookform/resolvers/zod'
import { Card, Container, Grid } from '@mui/material'
import MKBox from 'components/MKBox'
import MKButton from 'components/MKButton'
import MKInput from 'components/MKInput'
import MKProgress from 'components/MKProgress'
import MKTypography from 'components/MKTypography'
import React, { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useApi } from 'services/apiSlice'
import commonsEndpoints from 'services/endpoints/commons.endpoints'
import { z } from 'zod'

import MySelect from 'pages/components/MySelect'
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom'

const calculate = ({ companySize = 1, companyAmount = 1 }: any): number =>
    (((companySize === 0 ? 100 : companySize) / 10) * 3.3 + ((companyAmount === 0 ? 1000 : 100) / 100) * 0.7) / 50;

const businessOptions = [
    { value: 1, label: 'Lavandaria' },
    { value: 2, label: 'Cinema' },
    { value: 3, label: 'Hospital' },
    { value: 4, label: 'Clínica' },
    { value: 5, label: 'Cabeleireiro' },
    { value: 6, label: 'Estética' },
    { value: 7, label: 'Mecânica' },
    { value: 8, label: 'Restaurante' },
    { value: 9, label: 'Supermercado' },
    { value: 10, label: 'Hotelaria' },
    { value: 11, label: 'Fábrica' },
    { value: 12, label: 'Armarinho' },
    { value: 13, label: 'Loja' },
    { value: 14, label: 'Saúde' },
    { value: 15, label: 'Educação e Ensino' },
    { value: 16, label: 'Outro' }
]
const companySize = [
    { value: 10, label: 'ate 10 funcionários' },
    { value: 30, label: 'de 11 a 30 funcionários' },
    { value: 60, label: 'de 31 à 60 funcionários' },
    { value: 100, label: 'de 61 ate 100 funcionários' },
    { value: 0, label: 'mais de 100 funcionários' },
]
const companyAmount = [
    { value: 100, label: 'até 100.000,00 kzs de vendas por mês' },
    { value: 0, label: 'mais de 100.000,00 kzs de vendas por mês' },
]

const PricingWizard = ({ data: initialData = {} }: any) => {
    const [step, setStep] = useState(0)
    const [mydata, setData] = useState<any>(initialData)

    const onSubmit = async (data: any) => {
        let final = calculate(mydata)
        setData({ ...mydata, ...data, final })
    };

    //    useMemo(() => onSubmit({ final: calculate(mydata) }), [mydata])

    const Step = [Step1, Step2, Step3, Step4, Step5][step]

    return (

        <Grid container alignContent={'center'} justifyContent={'center'} py={10} color={'ButtonShadow'}>
            <Grid item xs={8}>
                <Grid container alignContent={'center'} justifyContent={'center'} spacing={5}>
                    <Grid item xs={6}>
                        <MKTypography variant="h3" my={1}>
                            Qual é o melhor pacote para o seu negócio?
                        </MKTypography>
                        <MKTypography variant="body2" color="text" mb={2}>
                            Entendemos que cada empresa é única, com necessidades e objetivos distintos. Estamos aqui para guiá-lo na escolha do plano que melhor atenda às suas metas e desafios.
                        </MKTypography>
                    </Grid>
                    <Grid item xs={6}>
                        <Card style={{ padding: 35 }}>
                            <MKBox component="section">
                                <Container>
                                    <Grid container item xs={12} md={10} py={1} mx="auto">

                                        <MKTypography variant="h2" color="text">
                                            {step + 1}
                                        </MKTypography>

                                        <MKProgress value={20 * (step + 1)} />
                                        <MKTypography variant="body2" color="text" mb={2}>
                                            A Nova erp oferece o suporte completo para seus funcionários.
                                        </MKTypography>
                                        <Step data={mydata} step={step} setStep={setStep} onSubmit={onSubmit} />

                                    </Grid>
                                </Container>
                            </MKBox>

                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
const Step1 = ({ step, setStep, onSubmit, data }: any) => {

    const { register, reset, control, formState: { errors }, watch, handleSubmit } = useForm({
        resolver: zodResolver(z.object({
            businessId: z.number()
        })),
        defaultValues: data

    });

    const submit = (data: any) => {
        setStep(step >= 4 ? 0 : step + 1)
        onSubmit(data)
    }

    return (<MKBox component="form" sx={{ width: '100%' }} onSubmit={handleSubmit(submit)}>
        <Grid container item xs={12} md={12} mx="auto">

            <MKTypography variant="h4" color="text">
                Tipo de Negócio
            </MKTypography>
            <MySelect
                style={{ width: '100%' }}
                control={control}
                options={businessOptions}
                {...register("businessId", { required: "Este campo é obrigatorio." })} />
            <MKBox color="#F00" fontSize={12}><ErrorMessage errors={errors} name="businessId" /></MKBox>
        </Grid>
        <Grid container item xs={12} md={10} py={1} mx="auto" alignContent={'center'} justifyContent={'center'}>
            <MKButton variant="gradient" color="info" type="submit">
                Segunte
            </MKButton>
        </Grid>

    </MKBox>)
}
const Step2 = ({ step, setStep, onSubmit, data }: any) => {

    const { register, reset, control, formState: { errors }, watch, handleSubmit } = useForm({
        resolver: zodResolver(z.object({
            companySize: z.number()
        })),
        defaultValues: data
    });

    const submit = (data: any) => {
        setStep(step >= 4 ? 0 : step + 1)
        onSubmit(data)
    }

    return (<MKBox component="form" sx={{ width: '100%' }} onSubmit={handleSubmit(submit)}>
        <Grid container item xs={12} md={12} mx="auto">

            <MKTypography variant="h4" color="text">
                Tamanho da Empresa
            </MKTypography>
            <MySelect
                style={{ width: '100%' }}
                control={control}
                options={companySize}
                {...register("companySize", { required: "Este campo é obrigatorio." })} />
            <MKBox color="#F00" fontSize={12}><ErrorMessage errors={errors} name="companySize" /></MKBox>
        </Grid>
        <Grid container item xs={12} md={10} py={1} mx="auto" alignContent={'center'} justifyContent={'center'}>
            <MKButton variant="gradient" color="info" type="submit">
                Segunte
            </MKButton>
        </Grid>

    </MKBox>)
}

const Step3 = ({ step, setStep, onSubmit, data }: any) => {

    const { register, reset, control, formState: { errors }, watch, handleSubmit } = useForm({
        resolver: zodResolver(z.object({
            companyAmount: z.number()
        })),
        defaultValues: data
    });

    const submit = (data: any) => {
        setStep(step >= 4 ? 0 : step + 1)
        onSubmit(data)
    }

    return (<MKBox component="form" sx={{ width: '100%' }} onSubmit={handleSubmit(submit)}>
        <Grid container item xs={12} md={12} mx="auto">

            <MKTypography variant="h4" color="text">
                Média de Facturação por mês
            </MKTypography>
            <MySelect
                style={{ width: '100%' }}
                control={control}
                options={companyAmount}
                {...register("companyAmount", { required: "Este campo é obrigatorio." })} />
            <MKBox color="#F00" fontSize={12}><ErrorMessage errors={errors} name="companyAmount" /></MKBox>
        </Grid>
        <Grid container item xs={12} md={10} py={1} mx="auto" alignContent={'center'} justifyContent={'center'}>
            <MKButton variant="gradient" color="info" type="submit">
                Segunte
            </MKButton>
        </Grid>

    </MKBox>)
}

const Step4 = ({ step, setStep, onSubmit, data }: any) => {

    const { register, reset, control, formState: { errors }, watch, handleSubmit } = useForm({
        resolver: zodResolver(z.object({
            name: z.string(),
            email: z.string().email(),
        })),
        defaultValues: data
    });

    const submit = (data: any) => {
        setStep(step >= 4 ? 0 : step + 1)
        onSubmit(data)
    }

    return (<MKBox component="form" sx={{ width: '100%' }} onSubmit={handleSubmit(submit)}>
        <Grid container item xs={12} md={12} mx="auto">

            <MKTypography variant="h4" color="text">
                Finalizando, teus dados
            </MKTypography>
            <MKInput
                mb={2}
                label="Seu nome"
                placeholder="Seu Nome completo"
                fullWidth
                {...register("name", { required: "Este campo é obrigatorio." })}
            />
            <MKBox color="#F00" fontSize={12}><ErrorMessage errors={errors} name="name" /></MKBox>
            <br />
            <MKInput
                mt={2}
                label="E-mail"
                placeholder="E-mail"
                fullWidth
                {...register("email", { required: "Este campo é obrigatorio." })}
            />
            <MKBox color="#F00" fontSize={12}><ErrorMessage errors={errors} name="email" /></MKBox>
        </Grid>
        <Grid container item xs={12} md={10} py={1} mx="auto" alignContent={'center'} justifyContent={'center'}>
            <MKButton variant="gradient" color="info" type="submit">
                Segunte
            </MKButton>
        </Grid>

    </MKBox>)
}
const Step5 = ({ step, setStep, data, onSubmit }: any) => {

    const navigate = useNavigate()
    const [state, setState] = React.useState(false);
    const [cookies, setCookie] = useCookies(["hubCalculator"]);

    const { data: resolved, resolve, loading, error } = useApi({
        service: commonsEndpoints.clientRequest.create
    })

    function handleCalculateCookies() {
        setStep(0)
        setCookie("hubCalculator", data, { path: "/" });
        resolve(data)
        navigate('/prices/calculator')
    }

    return (<MKBox component="form" sx={{ width: '100%' }}>
        <Grid container item xs={12} md={12} mx="auto">

        </Grid>
        <Grid container item xs={12} md={10} py={1} mx="auto" alignContent={'center'} justifyContent={'center'}>
            <br/>
            <MKButton variant="gradient" color="success" type="button" onClick={handleCalculateCookies}>
                Calcular
            </MKButton>
        </Grid>

    </MKBox>)
}

export default PricingWizard
