/*
=========================================================
* Nova erp React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Hiperbite (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Nova erp React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Nova erp React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import team1 from "assets/images/doubt.jpg";
import team2 from "assets/images/doubt.jpg";
import team3 from "assets/images/doubt.jpg";
import team4 from "assets/images/doubt.jpg";
import Faqs from "./Faqs";
import MKInput from "components/MKInput";
import { InputAdornment } from "@mui/material";
import { Search } from "@mui/icons-material";

function MainContent() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <MKTypography variant="h3" color="white">
              Ficou com alguma dúvida?
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
              Estas são algumas das perguntas mais frequentes que recebemos sobre nosso Software de RH
            </MKTypography>
            <MKInput
              placeholder="Search"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Search fontSize="small" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} lg={8}>
            <Faqs />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default MainContent;
