/*
=========================================================
* Nova erp React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Hiperbite (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Nova erp React components
import MKBox from "components/MKBox";

// Nova erp React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

function Counters() {
  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={100}
              suffix="+"
              title="Utilizadores em simultâneo"
              description="O nosso software ERP oferece a capacidade de ter múltiplos utilizadores a trabalhar de forma simultânea na plataforma"
            />
          </Grid>
          <Grid item xs={12} md={4} display="flex">
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, mx: 0 }} />
            <DefaultCounterCard
              count={40}
              suffix="+"
              title="Clientes"
              description="Mais de 40 empresas de diversos sectores de negócio utilizam o nosso sistema por todo o pais"
            />
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, ml: 0 }} />
          </Grid>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={2}
              title="Cópias de segurança diários"
              description="A segurança dos seus dados é uma das nossas maiores prioridades. Para assegurar que todas as informações críticas da sua empresa estão protegidas"
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Counters;
