
// Images
const client_0 = "/assets/images/logos/clients/client_0.png";
const client_1 = "/assets/images/logos/clients/client_1.png";
const client_2 = "/assets/images/logos/clients/client_2.png";
const client_3 = "/assets/images/logos/clients/client_3.png";
const client_4 = "/assets/images/logos/clients/client_4.png";
const client_5 = "/assets/images/logos/clients/client_5.png";
const client_6 = "/assets/images/logos/clients/client_6.png";
const client_7 = "/assets/images/logos/clients/client_7.png";
const client_8 = "/assets/images/logos/clients/client_8.png";
const client_9 = "/assets/images/logos/clients/client_9.png";
const client_10 = "/assets/images/logos/clients/client_10.png";
const client_11 = "/assets/images/logos/clients/client_11.png";
const client_12 = "/assets/images/logos/clients/client_12.png";
const client_13 = "/assets/images/logos/clients/client_13.png";
const client_14 = "/assets/images/logos/clients/client_14.png";
import MKBox from "components/MKBox";
import { Container, Grid } from "@mui/material";
import { useState } from "react";

const clients = [
    , client_0
    , client_1
    , client_2
    , client_3
    , client_4
    , client_5
    , client_6
    , client_7
    , client_8
    , client_9
    , client_10
    , client_11
    , client_12
    , client_13
    , client_14
]
const Clients = () => {
    return (
        <MKBox component="section" py={12}>
            <Container>
                <Grid container spacing={3} justifyContent="center">
                    {clients?.map((image: any) => <ClientBox image={image} />)}

                </Grid>
            </Container>
        </MKBox>
    );
}
const ClientBox = ({ image }: any) => {
    const [mouseOver, setMouseOver] = useState(false)
    return (
        <Grid item xs={6} md={4} lg={2}

            onMouseOver={() => setMouseOver(true)}
            onMouseOut={() => setMouseOver(false)}
        >
            <MKBox component="img" src={image} style={mouseOver ? {} : {
                '-webkit-filter': 'grayscale(100%)',
                'filter': 'grayscale(100%)'
            }} alt="Apple" width="100%" opacity={0.6} />
        </Grid>
    )
}

export default  Clients;