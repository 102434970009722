/*
=========================================================
* Nova erp React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Hiperbite (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Nova erp React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Presentation page components
import ExampleCard from "pages/Presentation/components/ExampleCard";



import bgImage from "assets/images/doubt.jpg";
import bgImageHighAvaliability from "assets/images/high-avaliability.png";

// Data
import data from "pages/Presentation/sections/data/pagesData";
import { Divider, Icon, Stack } from "@mui/material";
import MKButton from "components/MKButton";

function Pages() {
  const renderData = data.map(({ image, name, route }) => (
    <Grid item xs={12} md={6} sx={{ mb: { xs: 3, lg: 0 } }} key={name}>
      <Link to={route}>
        <ExampleCard image={image} name={name} display="grid" minHeight="auto" />
      </Link>
    </Grid>
  ));

  return (<>
    <MKBox component="section" py={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKBadge
            variant="contained"
            color="info"
            badgeContent="Eficiente e Eficaz"
            container
            sx={{ mb: 2 }}
          />
          <MKTypography variant="h2" fontWeight="bold">
            Porquê o Nova?
          </MKTypography>
          <MKTypography variant="body1" color="text">
            O Nova erp oferece uma série de vantagens significativas para empresas de todos os tamanhos. Aqui estão algumas razões pelas quais precisas adotar ao Nova ERP:
          </MKTypography>
        </Grid>
      </Container>
      <Container sx={{ mt: { xs: 8, lg: 16 } }}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={9} sx={{ mt: 3, px: { xs: 0, lg: 8 } }}>
            <HeaderOne lines={clound} />
          </Grid>
          <Grid item xs={12} lg={3}>
            <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
              <MKTypography variant="h3" fontWeight="bold" mb={1}>

                Sistema nas Núvens
              </MKTypography>
              <MKTypography variant="body2" fontWeight="regular" color="secondary" mb={1} pr={2}>
                Cloud computing redução de custos
                <Divider />
                Em tempos de crise, reduzir custos operacionais é fundamental para manter um negócio com alto nível de competitividade. Um sistema nas núvens pode ser visto como uma alternativa para empresas que pretendam optimizar as suas rotinas.
              </MKTypography>
            </MKBox>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={9} sx={{ mt: 3, px: { xs: 0, lg: 8 } }}>
            <HeaderOne lines={security} />
          </Grid>
          <Grid item xs={12} lg={3}>
            <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
              <MKTypography variant="h3" fontWeight="bold" mb={1}>
                Segurança
              </MKTypography>
              <MKTypography variant="body2" fontWeight="regular" color="secondary" mb={1} pr={2}>
                Proteção Garantida
                <Divider />
                O Nova protege informações pessoais e de negócios, permitindo que gerencie credenciais e identidades do utilizador por controle de acesso em três camadas e uma arquitectura integrada dos seus dados.
              </MKTypography>
            </MKBox>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={9} sx={{ mt: 3, px: { xs: 0, lg: 8 } }}>
            <Grid container spacing={3}>
              <HighAvaliability />
            </Grid>
          </Grid>
          <Grid item xs={12} lg={3}>
            <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
              <MKTypography variant="h3" fontWeight="bold" mb={1}>
                Alta disponibilidade
              </MKTypography>
              <MKTypography variant="body2" fontWeight="regular" color="secondary" mb={1} pr={2}>
                Operabilidade de alta disponibilidade
                <Divider />
                O conceito de alta disponibilidade visa garantir acesso às informações, de forma rápida e sem interrupções 24 horas por dia, 7 dias por semana. Para isso, o Nova usa contingências e duplicações em três camadas.
              </MKTypography>
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  </>
  );
}


const HighAvaliability = () => {
  return (

    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={3} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
            <ExampleCard image={bgImageHighAvaliability} name={""} display="grid" minHeight="auto" />
          </Grid>
          <Grid item xs={12} lg={9} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
            <Stack>
              <MKBox display="flex" alignItems="center" p={2}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">mediation</Icon>
                </MKBox>
                <MKTypography variant="body2" color="text" pl={2}>
                  Desempenho Otimizado
                  <br />
                  Garante que os sistemas operem de maneira eficiente
                </MKTypography>
              </MKBox>
              <MKBox display="flex" alignItems="center" p={2}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">settings_overscan</Icon>
                </MKBox>
                <MKTypography variant="body2" color="text" pl={2} sty>
                  Escalabilidade Dinâmica
                  <br />
                  aumentar recursos em tempo real conforme as necessidades do cliente,
                </MKTypography>
              </MKBox>
              <MKBox display="flex" alignItems="center" p={2}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">token</Icon>
                </MKBox>
                <MKTypography variant="body2" color="text" pl={2}>
                  Monitoramento Proativo.
                  <br />
                  comprometemo-nos para garantir que você tenha a melhor experiência possível
                </MKTypography>
              </MKBox>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  )
}

const clound: any[] = [
  { title: 'Acesso Remoto', text: 'Trabalhe de qualquer lugar, a qualquer momento, sem depender de uma infraestrutura física.' },
  { title: 'Economia de Custos', text: 'Reduza gastos com hardware e manutenção, pagando apenas pelos recursos utilizados.' },
  //{ title: 'Escalabilidade', text: 'Aumente ou reduza os recursos conforme as necessidades da sua empresa, de forma rápida e flexível.' },
  { title: 'Segurança Avançada', text: 'Servidores nas núvens contam com medidas de segurança robustas, protegendo seus dados contra ameaças físicas e virtuais.' },
  //{ title: 'Backup Automatizado', text: 'Realize backups regulares para garantir a segurança e disponibilidade dos seus dados.' },
  { title: 'Atualizações Contínuas', text: 'Mantenha-se sempre atualizado com as últimas versões e funcionalidades do software.' },
  { title: 'Alta Disponibilidade', text: 'Minimize o tempo de inatividade, assegurando a continuidade das operações.' },
  //{ title: 'Colaboração Eficiente', text: 'Facilite a comunicação e colaboração entre equipes distribuídas.' },
  //{ title: 'Integração com Outras Ferramentas', text: 'Integre facilmente o sistema com outras aplicações, melhorando a eficiência operacional.' },
  //{ title: 'Gestão Simplificada', text: 'A manutenção e gerenciamento da infraestrutura são responsabilidade do provedor de serviços na nuvem.' }
]
const security: any[] = [
  { title: 'Monitoramento e Detecção de Intrusões', text: 'O Nova erp empregam tecnologias avançadas de monitoramento que detectam atividades suspeitas ou tentativas de intrusão.' },
  { title: 'Criptografia de Dados', text: 'Os dados armazenados no nosso Sistema são criptografados para garantir a segurança.' },
  //{ title: 'Escalabilidade', text: 'Aumente ou reduza os recursos conforme as necessidades da sua empresa, de forma rápida e flexível.' },
  { title: 'Backup e Recuperação de Dados', text: 'Oferecemos soluções de backup automático e procedimentos de recuperação de dados em caso de falha de utilização.' },
  //{ title: 'Backup Automatizado', text: 'Realize backups regulares para garantir a segurança e disponibilidade dos seus dados.' },
  { title: 'Autenticação e Controle de Acesso', text: 'medidas de autenticação robustas, como senhas fortes e autenticação de dois fatores, para garantir que apenas usuários autorizados tenham acesso.' },
  //{ title: 'Alta Disponibilidade', text: 'Minimize o tempo de inatividade, assegurando a continuidade das operações.' },
  //{ title: 'Colaboração Eficiente', text: 'Facilite a comunicação e colaboração entre equipes distribuídas.' },
  //{ title: 'Integração com Outras Ferramentas', text: 'Integre facilmente o sistema com outras aplicações, melhorando a eficiência operacional.' },
  //{ title: 'Gestão Simplificada', text: 'A manutenção e gerenciamento da infraestrutura são responsabilidade do provedor de serviços na nuvem.' }
]

function HeaderOne({ lines }: any) {

  return (
    <MKBox component="div" position="relative" style={{ background: '#333', borderRadius: 25, padding: 15 }} >

      <MKBox
        display="flex"
        alignItems="center"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }: any) => bgImage,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} md={10} lg={10} flexDirection="column" justifyContent="center">
            {lines.map(({ text, title }: any) => <>
              <MKTypography
                variant="h1"
                color="white"
                mb={3}
                sx={({ breakpoints, typography: { size } }: any) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                {title}
              </MKTypography>
              <MKTypography variant="body1" color="white" opacity={0.8} pr={6} mr={6}>
                {text}
              </MKTypography>
            </>)}

          </Grid>
        </Container>
      </MKBox>
    </MKBox>
  );
}


export default Pages;
