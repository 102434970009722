


/**
=========================================================
* Nova erp React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Hiperbite (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";

// Nova erp React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import RequestDemo from "pages/components/RequestDemo/Index";

import bgImage from 'assets/images/macbook.png';
function RequestDemoCard(
  { image = bgImage,
    title = "Seja qual for o seu problema, nós temos a solução",
    description = "Temos soluções para empresas de qualquer tamanho, indústria ou setor.",
  }
    : any) {
  return (
    <Card>
      <MKBox position="relative" borderRadius="lg" mx={2} mt={-3}>
        <MKBox
          component="img"
          src={image}
          alt={title}
          borderRadius="lg"
          width="100%"
          position="relative"
          zIndex={1}
        />
        <MKBox
          borderRadius="lg"
          shadow="md"
          width="100%"
          height="100%"
          position="absolute"
          left={0}
          top={0}
          sx={{
            backgroundImage: `url(${image})`,
            transform: "scale(0.94)",
            filter: "blur(12px)",
            backgroundSize: "cover",
          }}
        />
      </MKBox>
      <MKBox p={3} mt={-1} textAlign="center">
        <MKTypography display="inline" variant="h5" fontWeight="regular">
          {title}
        </MKTypography>
        <MKBox mt={1} mb={3}>
          <MKTypography variant="body2" component="small" color="text" sx={{fontSize:12}}>
            {description}
          </MKTypography>
        </MKBox>

        <MKBox alignContent={'center'} justifyContent={'center'} >
          <RequestDemo />
        </MKBox>
      </MKBox>
    </Card>
  );
}


// Typechecking props for the RequestDemoCard
RequestDemoCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes,
};

export default RequestDemoCard;
