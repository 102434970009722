import { Container, Grid, Typography } from '@mui/material'
import MKBox from 'components/MKBox'
import React, { useEffect, useMemo, useState } from 'react'
import ItemBarList from './ItemBarList'
import BusinessDetails from './BusinessDetails'


const HelpCenter = () => {

    const [item, setItem] = useState<any>(0);

    return (
        <MKBox component="section" py={{ xs: 0, lg: 6 }}>
            <Container>
                <Typography variant="h1">Centro de Ajuda e Suporte</Typography>
                <Typography variant="body2" py={3}>
                Bem-vindo ao nosso Centro de Ajuda e Suporte! Estamos aqui para fornecer toda a assistência necessária para garantir que você tenha a melhor experiência com nossos produtos e serviços.


                </Typography>
                <Grid container>
                    <Grid item md={3}><ItemBarList item={item} setItem={setItem} /></Grid>
                    <Grid item md={9}><BusinessDetails item={item} /></Grid>
                </Grid>
            </Container>
        </MKBox>
    )
}

export default HelpCenter
