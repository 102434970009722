/**
=========================================================
* Nova erp React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Hiperbite (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { createContext, useEffect, useState } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Nova erp React themes
import theme from "./assets/theme";
import Presentation from "./layouts/pages/presentation";

// Nova erp React routes
import routes from "./routes";
import React from "react";

export const LoadingContext: any = createContext("");

export default function App() {
  const { pathname } = useLocation();
  const [loading, setLoading] = useState<any>(false);
  // Setting page scroll to 0 when changing the route
  /**
   * TODO: FIX ROUTE
   */
  useEffect(() => {
    if (document && document.documentElement && document.scrollingElement) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
  }, [pathname]);

  const getRoutes = (allRoutes: any) =>
    allRoutes.map((route: any) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          {getRoutes(routes)}
          <Route path="/" element={<Presentation />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </ThemeProvider>
    </LoadingContext.Provider>
  );
}
