/*
=========================================================
* Nova erp React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Hiperbite (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Nova erp React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Nova erp React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import team1 from "assets/images/vectors/stock/11065.jpg";
import team2 from "assets/images/vectors/stock/15709.jpg";
import team3 from "assets/images/vectors/stock/3497810.jpg";
import team4 from "assets/images/vectors/stock/4016257.jpg";
import team5 from "assets/images/vectors/stock/20945972.jpg";
import team6 from "assets/images/vectors/stock/Checklist.jpg";
import team7 from "assets/images/vectors/stock/NA_SEP._29.jpg";
import team8 from "assets/images/vectors/stock/20945972.jpg";

function Modules() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              Principais Módulos
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
              A Nova ERP foi projetado para garantir, controlar e otimizar o gerenciamento de todos
              os itens físicos que uma organização mantém em stock com os seguintes módulos
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team1}
                name="Registo de Produtos"
                position={{ color: "info", label: "Gestão de Cadastramento" }}
                description="O sistema permite cadastrar todos os produtos e itens em stock, incluindo informações
                detalhadas como descrição, código, categoria, preço, entre outros."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team2}
                name="Gerenciamento de Fornecedores"
                position={{ color: "info", label: "Gestão por acompanhamento" }}
                description="O sistema permite o cadastro e acompanhamento de informações sobre fornecedores, incluindo
                detalhes de contato, histórico de compras, entre outros."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team3}
                name="Controle de Movimentação de Stock"
                position={{ color: "info", label: "Gestão de entradas e saídas" }}
                description="O sistema permite registar compras, produção, vendas e devoluções de
                produtos."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team4}
                name="Controle de Quantidade em Stock"
                position={{ color: "info", label: "Gráficos de Monitoramento" }}
                description="O sistema permite monitorar em tempo real a quantidade disponível de cada item no
                stock."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team5}
                name="Reordenação Automática"
                position={{ color: "info", label: "Gestão de reabastecimento" }}
                description="O sistema emite alertas ou ações automáticas para reabastecer produtos quando os níveis
                mínimos são atingidos"
              />
            </MKBox>
          </Grid>
          
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team6}
                name="Gestão Multilocal"
                position={{ color: "info", label: "Integração de filiais e sistemas ERP" }}
                description="O sistema dá suporte para múltiplos locais de armazenamento, útil para organizações com
                operações em diferentes filiais ou armazéns"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team7}
                name="Avaliação de Inventário"
                position={{ color: "info", label: "Gestão de Inventário" }}
                description="Cálculo do valor total do inventário unsando métodos de avaliação como
                PEPS (Primeiro que Entra, Primeiro que Sai) ou UEPS (Último que Entra, Primeiro
                que Sai"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team8}
                name="Suporte Técnico e Atualizações"
                position={{ color: "info", label: "Garantia de Atualizações Regulares" }}
                description="Disponibilidade de suporte técnico e atualizações regulares para garantir o bom
                funcionamento do sistema"
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Modules;
