/*
=========================================================
* Nova erp React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Hiperbite (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Nova erp React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Nova erp React examples
import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";

import moment from 'moment';
import { useState } from "react";
function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography variant="h2">Experiências</MKTypography>
          <MKTypography variant="h2" color="info" textGradient mb={2}>
            de Sucesso com o Nosso Software ERP
          </MKTypography>
          <MKTypography variant="body1" color="text" mb={2}>
            Conheça os depoimentos de clientes que transformaram suas operações com o nosso software ERP.
          </MKTypography>
          <MKTypography variant="p" style={{ fontSize: 12 }} color="text" mb={2}>
            Descubra como eles superaram desafios, aumentaram a eficiência e impulsionaram o crescimento com a nossa solução. Suas histórias são a nossa maior fonte de inspiração e motivação para continuar oferecendo soluções de excelência. Conheça agora os relatos de sucesso de quem escolheu o nosso software para alcançar resultados extraordinários.
          </MKTypography>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 8 }}>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              name="João Silva, Diretor de Operações na ABC Indústrias"
              date={moment('2023-08-08').fromNow()}
              review="Estou impressionado com a flexibilidade do Nova. Conseguimos personalizá-lo de acordo com as necessidades específicas da nossa empresa. Isso nos deu uma vantagem competitiva no mercado. Recomendo fortemente a todos que buscam uma solução completa e adaptável."
              rating={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              color="warning"
              name="Daniel Vieira"
              date={moment('2023-09-13').fromNow()}
              review="Desde que começamos a usar o Nova ERP da Hiperbite, nossa produtividade disparou! A integração de todas as operações em um único sistema nos permitiu economizar tempo e minimizar erros. Definitivamente, uma escolha inteligente para otimizar nossos processos."
              rating={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              name="Ana Costa, Diretora Financeira na LUBA Comercial"
              date={moment('2023-09-01').fromNow()}
              review="O Nova ERP revolucionou nossa gestão financeira. Agora, temos uma visão clara e detalhada das nossas finanças, o que nos ajuda a tomar decisões mais informadas. A facilidade de uso e suporte excepcional tornam este sistema indispensável para nossa empresa.."
              rating={5}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

// Images
import client_0 from "assets/images/logos/clients/client_0.png";
import client_1 from "assets/images/logos/clients/client_1.png";
import client_2 from "assets/images/logos/clients/client_2.png";
import client_3 from "assets/images/logos/clients/client_3.png";
import client_4 from "assets/images/logos/clients/client_4.png";
import client_5 from "assets/images/logos/clients/client_5.png";
import client_6 from "assets/images/logos/clients/client_6.png";
import client_7 from "assets/images/logos/clients/client_7.png";
import client_8 from "assets/images/logos/clients/client_8.png";
import client_9 from "assets/images/logos/clients/client_9.png";
import client_10 from "assets/images/logos/clients/client_10.png";
import client_11 from "assets/images/logos/clients/client_11.png";
import client_12 from "assets/images/logos/clients/client_12.png";
import client_13 from "assets/images/logos/clients/client_13.png";
import client_14 from "assets/images/logos/clients/client_14.png";

const clients = [
  , client_0
  , client_1
  , client_2
  , client_3
  , client_4
  , client_5
  , client_6
  , client_7
  , client_8
  , client_9
  , client_10
  , client_11
  , client_12
  , client_13
  , client_14
]
const ClinentsSections=()=>{
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} justifyContent="center">
          {clients?.map((image: any) => <ClientBox image={image} />)}

        </Grid>
      </Container>
    </MKBox>
  );
}
const ClientBox = ({ image }: any) => {
  const [mouseOver, setMouseOver] = useState(false)
  return (
    <Grid item xs={6} md={4} lg={2}

      onMouseOver={() => setMouseOver(true)}
      onMouseOut={() => setMouseOver(false)}
    >
      <MKBox component="img" src={image} style={mouseOver ? {} : {
        '-webkit-filter': 'grayscale(100%)',
        'filter': 'grayscale(100%)'
      }} alt="Apple" width="100%" opacity={0.6} />
    </Grid>
  )
}

export default Information;
