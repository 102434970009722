import { Card, Container, Grid } from '@mui/material'
import MKBox from 'components/MKBox'
import MKButton from 'components/MKButton'
import MKInput from 'components/MKInput'
import MKProgress from 'components/MKProgress'
import MKTypography from 'components/MKTypography'
import React, { useState } from 'react'

const PricingWizard = () => {
    const [step, setStep] = useState(0)
    return (

        <Grid container alignContent={'center'} justifyContent={'center'} py={10} color={'ButtonShadow'}>
            <Grid item xs={8}>
                <Grid container alignContent={'center'} justifyContent={'center'} spacing={5}>
                    <Grid item xs={6}>
                        <MKTypography variant="h3" my={1}>
                            Qual é o melhor pacote para o seu negócio?
                        </MKTypography>
                        <MKTypography variant="body2" color="text" mb={2}>
                            Entendemos que cada empresa é única, com necessidades e objetivos distintos. Estamos aqui para guiá-lo na escolha do plano que melhor atenda às suas metas e desafios.
                        </MKTypography>
                    </Grid>
                    <Grid item xs={6}>
                        <Card style={{ padding: 35 }}>
                            <MKBox component="section">
                                <Container>
                                    <Grid container item xs={12} md={10} py={1} mx="auto">

                                        <MKTypography variant="h2" color="text">
                                            {step + 1}
                                        </MKTypography>

                                        <MKProgress value={20 * (step + 1)} />
                                        <MKTypography variant="body2" color="text" mb={2}>
                                            A Nova erp oferece o suporte completo para seus funcionários.
                                        </MKTypography>

                                        <MKInput label="Success" fullWidth success />
                                    </Grid>
                                    <Grid container item xs={12} md={10} py={1} mx="auto" alignContent={'center'} justifyContent={'center'}>
                                        <MKButton variant="gradient" color="info" onClick={() => setStep(step >= 4 ? 0 : step + 1)}>
                                            Segunte
                                        </MKButton>
                                    </Grid>
                                </Container>
                            </MKBox>

                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default PricingWizard
