import React from 'react'

import { Map, Marker } from "pigeon-maps"

function MapsLocation() {
    return (
        <Map defaultCenter={[-8.991523, 13.399624]} defaultZoom={15}>
            <Marker width={50} anchor={[-8.991523, 13.399624]} />
        </Map>
    )
}
export default MapsLocation
