/**
=========================================================
* Nova erp React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Hiperbite (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Nova erp React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui material components
import Icon from "@mui/material/Icon";

import Accounting from "pages/Products/Accounting";
import Sales from "pages/Products/Sales";
import HumanResources from "pages/Products/HumanResources";
import PaymentIon from '@mui/icons-material/Payment';
import Prices from "pages/Prices/Prices";
import Stock from "pages/Products/Stock";
import BusinessPage from "pages/Business";
import FAQs from "pages/Suport/FAQs";
import Suport from "pages/Suport";
import Legal from "pages/Suport/Legal";
import ContactUsPage from "layouts/pages/landing-pages/contact-us";
import { ContactMail } from "@mui/icons-material";
import HubCalculator from "pages/Prices/Prices/HubCalculator";

const routes: any = [
  {
    name: "Produtos",
    icon: <Icon>dashboard</Icon>,
    columns: 3,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "nova",
        collapse: [
          {
            icon: <Icon>dashboard</Icon>,
            name: "Recursos Humanos",
            route: "/products/humans-resources",
            component: <HumanResources />,
          },
          {
            name: "Vendas",
            route: "/products/sales",
            component: <Sales />,
          },
          {
            name: "Contabilidade",
            route: "/products/accounting",
            component: <Accounting />,
          },
          {
            name: "Stock",
            route: "/products/stock",
            component: <Stock />,
          },
        ],
      },

    ],
  },
  {
    name: "Negócios",
    icon: <Icon>view_day</Icon>,
    route: "/business",
    component: <BusinessPage />,
  },
  {
    name: "Documentação e Suporte",
    icon: <Icon>article</Icon>,
    image: '',
    route: "/suport/help-center",
    collapse: [
      {
        name: "Centro de Ajuda",
        description: "Vídeos explicativos para demonstrar o uso eficaz do software em cenários do mundo real",
        route: "/suport/help-center",
        component: <Suport />,
      },
      {
        name: "Tutoriais em Vídeo",
        description: "Vídeos explicativos para demonstrar o uso eficaz do software em cenários do mundo real",
        route: "/suport/help-center#tutorials",
        component: <Suport />,
      },
      {
        name: "Suporte Técnico",
        description: "Informações de contato e processos para obter suporte técnico adicional, caso necessário.",
        route: "/suport/help-center#user-guide",
        component: <Suport />,
      },
      {
        name: "Configuração Inicial",
        description: " Passos para a configuração inicial do sistema, incluindo definição de permissões de usuário e configurações de preferências.",
        route: "/suport/help-center#initial-config",
        component: <Suport />,
      },
      {
        name: "FAQs e Solução de Problemas",
        description: " Uma lista de perguntas frequentes e soluções para possíveis problemas que possam surgir",
        route: "/suport/faqs",
        component: <FAQs />,
      },
    ],
  },
  {
    name: "Dados Legais",
    icon: <Icon>article</Icon>,
    image: '',
    active: false,
    route: "/legal",
    collapse: [
      {
        name: "Termos & Condições",
        route: "/legal/:id",
        component: <Legal />,
      },
      {
        name: "Politicas De Privacidade",
        route: "/legal/:id",
        component: <Legal />,
      },
      {
        name: "Licenses (EULA)",
        route: "/legal/:id",
        component: <Legal />,
      },
      {
        name: "Configuração Inicial",
        description: " Passos para a configuração inicial do sistema, incluindo definição de permissões de usuário e configurações de preferências.",
        route: "/suport/help-center#initial-config",
        component: <Suport />,
      },
      {
        name: "FAQs e Solução de Problemas",
        description: " Uma lista de perguntas frequentes e soluções para possíveis problemas que possam surgir",
        route: "/suport/faqs",
        component: <FAQs />,
      },
    ],
  },
  {
    name: "Preços",
    icon: <PaymentIon />,
    route: "/prices",
    component: <Prices />,
  },
  {
    name: "Preços",
    icon: <PaymentIon />,
    route: "/prices/calculator",
    active: false,
    component: <HubCalculator />,
  },
  {
    name: "Contactos",
    icon: <ContactMail />,
    route: "/contacts",
    component: <ContactUsPage />,
  },
];

export default routes;
