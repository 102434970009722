import { Typography } from '@mui/material'
import React from 'react'

const PrivacyPolicy = () => {
    return (
        <div>
            <p><b>Hiper Dados Lda</b>, doravante <b>Nova</b> ou <b>Hiperbite</b>, garante a proteção de todos os dados pessoais que lhe sejam disponibilizados mediante a utilização deste website, optimizando continuamente o conjunto de procedimentos e técnicas em conformidade com o Regulamento Geral sobre a Protecção de Dados – RGPD – aprovado pelo Regulamento (EU) 2016/679 de 26 de Abril de 2016.</p>
            <p>Esta Política pretende dar a conhecer aos utilizadores deste website o modo como a Hiperbite trata os seus dados pessoais recolhidos através da utilização deste website, bem como os que são disponibilizados através do Nova.</p>
            <p>Assim, cada vez que utiliza este website os dados pessoais que transmitir serão tratados em conformidade com a Política de Privacidade vigente a cada momento, pelo que se sugere a consulta regular da presente Política de Privacidade.</p>
            <p>Os dados recolhidos pelo site são introduzidos no sistema informático que os trata, onde são processados automaticamente, destinando-se os dados à gestão de serviços do site.</p>
            <p>Todos os colaboradores do Nova estão abrangidos por uma obrigação de confidencialidade relativamente aos dados aos quais tenham acesso no âmbito das operações da respectiva base informática, estando devidamente informados da importância do cumprimento desse dever legal de sigilo e sendo responsáveis pelo cumprimento dessa obrigação de confidencialidade.</p>
            <Typography variant='h5'>1 - Responsável pelo Tratamento</Typography>
            <p>O Responsável pelo Tratamento dos seus dados pessoais é a HiperbiteLda. NIPC 509442013, com sede na Rua da Lionesa, 446, Edifício G20, 4465-671 Leça do Balio, Portugal.</p>
            <p>A Hiperbite trata as seguintes categorias de dados pessoais em função de diferentes finalidades:</p>
            <ul><li>Dados necessários para a prestação de serviços, execução de contrato e cumprimento de obrigações legais: Dados de identificação pessoal – nome; endereço de correio electrónico; NIF, morada.</li>
                <li>Dados decorrentes de reclamações/pedidos de exercício de direitos: Dados de identificação pessoal – Nome; morada, documento legal de identificação.</li></ul>
            <Typography variant='h5'>2 - Finalidades do Tratamento</Typography>
            <p>A Hiperbite utiliza ou poderá utilizar os seus dados pessoais no âmbito das seguintes finalidades:</p>
            <ul>
                <li>Prestação de serviços e execução de contrato em que seja parte;</li>
                <li>Cumprimento de obrigações legais a que a Hiperbite está vinculada;</li>
                <li>Envio de comunicações de marketing directo;</li>
                <li>Gestão de pedidos de contacto, informação e reclamações;</li>
            </ul>
            <p>Antes de lhe ser enviada a primeira comunicação de marketing, ser-lhe-á solicitado o seu consentimento expresso para o envio deste tipo de comunicações.</p>
            <Typography variant='h5'>3 - Licitude do Tratamento</Typography>
            <p>A Hiperbite apenas tratará os seus dados pessoais quando existir um fundamento de licitude que legitime o respetivo tratamento, designadamente:</p>
            <ul><li>Diligências pré-contratuais e execução de um contrato;</li>
                <li>Obrigação legal;</li>
                <li>Interesse público ou exercício da autoridade pública;</li>
                <li>Interesse legítimo desde que tais interesses não se sobreponham aos seus direitos e liberdades fundamentais;</li>
                <li>Consentimento;</li>
                <li>Defesa dos seus interesses vitais ou de terceiro.</li>
            </ul>
            <Typography variant='h5'>4 - Prazo de Conservação dos Dados</Typography>
            <p>A Hiperbite apenas conservará os seus dados pessoais durante o tempo estritamente necessário para atingir a finalidade para a qual os mesmos foram recolhidos. A Hiperbite pode ainda conservar alguns dos seus dados pessoais, na medida em que tal seja necessário para cumprir com as suas obrigações legais, bem como para gerir ou fazer valer os seus direitos. Findos os prazos de conservação acima referidos, os dados pessoais serão eliminados permanentemente das bases de dados da Hiperbite.</p>
            <Typography variant='h5'>5 - Partilha de Dados</Typography>
            <p>A Hiperbite apenas recorrerá a entidades terceiras na medida do estritamente necessário para prestar o desenvolvimento da sua actividade, assegurando que as mesmas cumprirão as suas obrigações em matéria de privacidade e proteção de dados pessoais, através da celebração de um Acordo de Subcontratação por forma a regular todos os aspetos inerentes às atividades de tratamento dos seus dados pessoais.</p>
            <p>A Hiperbite não efectua transferências internacionais dos seus dados para um país terceiro ou organização internacional que se encontre fora da União Europeia.</p>
            <Typography variant='h5'>6 - Direitos dos Titulares dos Dados</Typography>
            <p>Nos termos da legislação vigente em matéria de proteção dos dados pessoais, informamos que são garantidos ao titular dos dados:</p>
            <p><b>Direito de ser informado:</b> no momento da recolha dos dados pessoais:</p>
            <ul><li>Dos contactos do responsável pelo tratamento;</li>
                <li>Da(s) finalidade(s) de tratamento;</li>
                <li>Do prazo de conservação;</li>
                <li>Dos seus direitos enquanto titulares de dados pessoais;</li>
                <li>Da possibilidade de recurso à autoridade de controlo para apresentação de queixa (e respectivos contactos);</li>
                <li>Da (eventual) existência de decisões automatizadas e/ou profiling;</li>
                <li>Saber onde estão armazenados, e como são tratados os seus dados;</li>
                <li>Ser informado de como a Hiperbite cumpre com suas as obrigações de proteção de dados.</li>
            </ul>
            <p><b>Direito de acesso:</b> o titular pode aceder aos seus dados pessoais; confirmar a existência ou não de tratamento dos seus dados e requerer uma cópia dos mesmos.</p>
            <p><b>Direito de oposição </b>ao tratamento: o titular tem o direito de se opor ao tratamento por motivos relacionados com a sua situação particular.</p>
            <p><b>Direito de rectificação:</b> o titular tem o direito de rectificar os seus dados pessoais se os mesmos estiverem incorrectos, desactualizados ou incompletos.</p>
            <p><b>Direito ao apagamento:</b> o titular dos dados pode solicitar, a qualquer momento, a eliminação de todos os seus dados pessoais recolhidos pelo Nova.</p>
            <p><b>Direito de Portabilidade:</b> o titular dos dados tem o direito de receber os dados pessoais que lhe digam respeito e que tenha fornecido, num formato estruturado, de uso corrente e de leitura automática; e o direito de transmitir esses dados a outro Responsável pelo tratamento sempre que:</p>
            <ul><li>O tratamento se basear no consentimento ou num contrato; e</li>
                <li>O tratamento for realizado por meios automatizados.</li>
            </ul>
            <p><b>Direito à limitação do tratamento:</b> em certas situações, que devem ser ponderadas caso a caso, o titular tem o direito a solicitar a restrição do tratamento dos seus dados.</p>
            <p>O exercício dos direitos por parte dos titulares poderá ser feito através do endereço de e-mail info@Nova.pt, bem ainda por escrito para o endereço postal da Hiperbite, na qual efectue a sua identificação e indique a situação que constitui objecto da sua solicitação, com:</p>
            <p><b>A/C: Encarregado de Protecção de Dados (EPD/DPO)</b></p>
            <p><b>Morada:</b> Rua da Lionesa 446, Edifício G20, 4465-671 Leça do Balio, Portugal</p>
            <p>Poderá ainda apresentar queixa junto da Comissão Nacional de Proteção de Dados (CNPD) e/ou obter reparação e/ou indemnização, se considerar que o tratamento realizado viola os seus direitos e/ou a legislação em matéria de proteção de dados pessoais e de privacidade aplicáveis.</p>
            <Typography variant='h5'>7 - Armazenamento e Segurança dos Dados</Typography>
            <p>A Hiperbite utiliza um conjunto de tecnologias e procedimentos de segurança adequados para proteger os seus dados pessoais do acesso, uso ou divulgação não autorizados, tais como: controlo de acessos; registo de atividade dos utilizadores e testes de penetração.</p>
            <p>Os seus dados pessoais poderão ser armazenados para alojamento e backup em servidores localizados na União Europeia, em datacenters protegidos por equipas de segurança, num ambiente seguro, com acesso restrito e controlado, video-vigilância 24 horas por dia, e cuja gestão está a cargo da empresa Amazon Web Services, com as quais a Hiperbite tem celebrados Acordos de Subcontratação.</p>
            <p>A Hiperbite guarda os seus dados pessoais em servidores que se encontram em ambiente seguro, protegidos de acessos não autorizados.</p>
            <p>Para que possamos proteger os dados dos nossos utilizadores contra as ameaças que existem online e que podem, eventualmente, comprometer os dados que utilizamos, adotamos medidas de proteção que são revistas com a regularidade necessária de forma a manter a segurança da navegação.</p>
            <p>Solicitamos, também, aos nossos utilizadores que protejam os seus dispositivos contra vírus ou outras ameaças que possam comprometer a sua segurança, através de programas específicos para o efeito.</p>
            <p>O tratamento de dados e a recolha de informações do utilizador está limitada a um número restrito de pessoas. Deste modo, podemos salvaguardar acessos indevidos aos sistema e comprometimento de dados pessoais.</p>
            <p>Compete, porém, a todos os utilizadores assegurar que os equipamentos que utilizam estão protegidos contra softwares nocivos, vírus informáticos e worms, bem como garantir a configuração segura do seu programa de navegação.</p>

            <p><b>Política de Privacidade - Versão 3.01 – 27 de Julho de 2023</b></p>

        </div>
    )
}

export default PrivacyPolicy
