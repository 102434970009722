/*
=========================================================
* Nova erp React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Hiperbite (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Nova erp React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Nova erp React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Image
import bgImage from "assets/images/illustrations/illustration-reset.jpg";
import { useApi } from "services/apiSlice";
import commonsEndpoints from "services/endpoints/commons.endpoints";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMemo } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { Alert, CircularProgress, Divider, Snackbar } from "@mui/material";
import { z } from "zod";
import MapsLocation from "./MapsLocation";
import Swal from "sweetalert2";

function ContactUs() {


  const { data, resolve, loading, error } = useApi({
    service: commonsEndpoints.clientRequest.create
  })
  const { register, reset, formState: { errors }, watch, handleSubmit } = useForm({
    resolver: zodResolver(schema)
  });

  useMemo(() => {
    if (data?.date)
      Swal.fire({
        icon: "success",
        title: ' Mensagem submetida com successo',
        text: 'Agradecemos por entrar em contato! Fique atento(a) ao seu e-mail, responderemos em breve'
      });
    reset()
  }, [data])

  const onSubmit = async (data: any) => {
    console.log(data);
    const newData = {
      type: 3,//DEMO REQUEST
      message: data?.message,
      client: {
        name: "default",
        email: "default@nova.ao",
        type: 0,
        telephone: 0,
        country: "AO",
        ...data
      }
    }

    resolve(newData);

  };

  return (
    <>
      <MKBox position="fixed" top="0.5rem" width="100%" sx={{ zIndex: 9999 }}>
        <DefaultNavbar
          routes={routes}
          action={{
            type: "external",
            route: "https://www.creative-tim.com/product/material-kit-react",
            label: "free download",
            color: "info",
          }}
        />
      </MKBox>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} lg={6}>
          <MKBox
            position="sticky" top="100px"
            width="calc(100% - 2rem)"
            height="calc(100vh - 2rem)"
            borderRadius="lg"
            ml={2}
            mt={-50}
          >
            <MapsLocation />
          </MKBox>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={7}
          lg={6}
          xl={4}
          ml={{ xs: "auto", lg: 6 }}
          mr={{ xs: "auto", lg: 6 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <MKBox
              p={2}
              mx={2}
            >
              <MKTypography variant="h1" color="info">
                Fale connosco
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKTypography variant="body2" color="text" mb={3}>
                Para mais dúvidas, incluindo oportunidades de parceria, envie um e-mail para nova@hiperbite.com ou entre em contato através do nosso formulário de contato.
              </MKTypography>
              <MKBox width="100%" component="form" method="post" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <MKInput
                      label="Nome completo*"
                      fullWidth
                      {...register("name", { required: "Este campo é obrigatorio." })}
                    />
                    <MKBox sx={{ color: '#F00', fontSize: 12 }}><ErrorMessage errors={errors} name="name" /></MKBox>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      type="email"
                      label="Email *"
                      fullWidth
                      {...register("email", { required: "Este campo é obrigatorio." })}
                    />
                    <MKBox sx={{ color: '#F00', fontSize: 12 }}><ErrorMessage errors={errors} name="email" /></MKBox>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      type="number"
                      label="Telefone (opcional)"
                      fullWidth
                      {...register("telephone", { required: "Este campo é obrigatorio." })}
                    />
                    <MKBox sx={{ color: '#F00', fontSize: 12 }}><ErrorMessage errors={errors} name="telephone" /></MKBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MKInput
                      label="Em que podemos ajudá-lo?"
                      placeholder="Descreva a sua mensagem em pelo menos 500 caracteres"
                      multiline
                      fullWidth
                      rows={6}
                      {...register("message", { required: "Este campo é obrigatorio." })}
                    />
                    <MKBox sx={{ color: '#F00', fontSize: 12 }}><ErrorMessage errors={errors} name="message" /></MKBox>
                  </Grid>
                </Grid>
                <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                  <MKButton type="submit" variant="gradient" color="info">

                    {loading ? <><CircularProgress /> Processando </> :
                      <>Enviar mensagem</>}
                  </MKButton>
                </Grid>

                <Grid container spacing={3}>
                  <Divider />
                  <Grid item md={6} xs={12} ><MKTypography variant="body2" color="text">Facebook:</MKTypography><MKTypography variant="b" color="text">@hipertite</MKTypography></Grid>
                  <Grid item md={6} xs={12} ><MKTypography variant="body2" color="text">Istagram:</MKTypography><MKTypography variant="b" color="text">@hiperbite</MKTypography></Grid>
                  <Grid item md={6} xs={12} ><MKTypography variant="body2" color="text">LinkedIn:</MKTypography><MKTypography variant="b" color="text">@hipertite</MKTypography></Grid>
                  <Grid item md={6} xs={12} ><MKTypography variant="body2" color="text">WhatsApp:</MKTypography><MKTypography variant="b" color="text">+244 935 140 392</MKTypography></Grid>
                  <Grid item md={12} xs={12}><MKTypography variant="body2" color="text">Website: </MKTypography><MKTypography variant="b" color="text">www.hipertite.ao e www.nova.ao</MKTypography></Grid>
                  <Grid item md={12} xs={12}><MKTypography variant="body2" color="text">Endereço: </MKTypography><MKTypography variant="b" color="text">Zango 0, Vila pacífica I12-1, APT 1101<br />Luanda Angola</MKTypography></Grid>
                </Grid>
              </MKBox>
            </MKBox>
          </MKBox>
        </Grid>

      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

const schema = z.object({
  email: z.string().email(),
  telephone: z.string().optional().nullable(),
  name: z.string().min(5).max(50),
  message: z.string().min(5).max(500),
});

export default ContactUs;
