/*
=========================================================
* Nova erp React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Hiperbite (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Nova erp React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Nova erp React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
const image1 = "/assets/images/vectors/rh/19729.png";
const image2 = "/assets/images/vectors/rh/21404.png";
const image3 = "/assets/images/vectors/rh/4141219.png";
const image4 = "/assets/images/vectors/rh/4198717.png";
const image5 = "/assets/images/vectors/rh/44658.png";
const image6 = "/assets/images/vectors/rh/4565.png";

function Modules() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              Principais Módulos
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
              Porque o nosso Software de RH ajuda a impulsionar sua empresa e a valorizar seus colaboradores. São mais de 30h de trabalho economizadas todos os meses!
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={image1}
                name="Cadastramento e gestão de Colaboradores"
                position={{ color: "info", label: "Gestão de Colaboradores" }}
                description="Simplifique e otimize a gestão de colaboradores e livre-se das preocupações administrativas."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={image2}
                name="Folhas de Pagamentos"
                position={{ color: "info", label: "Gestão de Remuneração e folhas de pagamento" }}
                description="Organize, revise e automatize as informações e documentos necessários para gerar a folha de pagamento, eliminando planilhas e papéis."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={image3}
                name="Ausências, Ponto, Férias e Horas Extras"
                position={{ color: "info", label: "Controle da Jornada de Trabalho" }}
                description="Monitore as horas de trabalho, aprove dias de férias ou folgas, planeje os turnos e escalas da equipe: tudo em uma única plataforma!"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={image4}
                name="Recrutamento e Seleção"
                position={{ color: "info", label: "Gestão de Talentos" }}
                description="Simplifique o onboarding e offboarding, recrute novos talentos e desenvolva suas equipes com uma plataforma de gestão de pessoas."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={image5}
                name="Avaliação e desempenho"
                position={{ color: "info", label: "Gestão de Talentos" }}
                description="processo fundamental nas organizações, proporciona feedback construtivo, identifica áreas de desenvolvimento e reconhece os pontos fortes dos colaboradores"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={image6}
                name="Portal do Colaborador"
                position={{ color: "info", label: "Portal online e App mobile" }}
                description="Os funcionários têm acesso a informações relevantes sobre sua empregabilidade na empresa. Esse portal oferece os recursos que facilitam a comunicação interna"
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Modules;
