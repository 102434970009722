/*
=========================================================
* Nova erp React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Hiperbite (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Nova erp React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Nova erp React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
const image1 = "/assets/images/vectors/seller/12023.png";
const image2 = "/assets/images/vectors/seller/6505486.png";
const image3 = "/assets/images/vectors/seller/234234.png";
const image4 = "/assets/images/vectors/seller/234234.png"
const image5 = "/assets/images/vectors/seller/3873305.png";
const image6 = "/assets/images/vectors/seller/453434.png";

function Modules() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              Principais Módulos
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
              Porque o nosso Software de gestão de vendas maximiza o potencial do seu negócio. Tenha melhor controle sobre as vendas, clientes e relatórios da sua Empresa.
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={image1}
                name="Emissão de Facturas Electrónica"
                position={{ color: "info", label: "Facturação Electrónica" }}
                description="Crie facturas eletrônicas em conformidade com os padrões fiscais e legais do país tornando-o eficiente e ecologicamente correto."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={image2}
                name="Gestão de Clientes"
                position={{ color: "info", label: "Conheça seus Clientes" }}
                description="oferece um Registo de seus clientes, contatos, histórico de compras para um relacionamento duradouro com eles."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={image3}
                name="Gestão de Productos"
                position={{ color: "info", label: "Controle de Productos" }}
                description="Garanta que as informações sobre os itens vendidos estejam atualizados e tenha uma visão ampla de seus productos."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={image4}
                name="Relatórios e Análise"
                position={{ color: "info", label: "Controle dos Resultados" }}
                description="Tome as melhores decisões e estratégia para sua empresa baseando-se nos relatórios demonstrados."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={image5}
                name="POS (Point of Sale)"
                position={{ color: "info", label: "Ponto de venda" }}
                description="POS Loja destinado ao comércio em locais de venda ao público e POS Restaurante destinado a área de Restauração."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={image6}
                name="Gestão de Utilizadores"
                position={{ color: "info", label: "Níveis de Acesso" }}
                description="Permite atribuir diferentes níveis de acesso e permissões aos utilizadores do sistema, garantido segurança dos dados."
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Modules;
