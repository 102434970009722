import React, { useMemo, useState } from 'react'
import LegalPage from './LegalPage'
import { useParams } from 'react-router-dom'
import TermsAndConditions from './contents/TermsAndConditions'

import { Container, Grid, Typography } from '@mui/material'
import PrivacyPolicy from './contents/PrivacyPolicy'

const pageContents = [
    {
        key: 'terms-and-conditions',
        title: 'Termos e Condições',
        descriptions: 'Estes são os Termos e Condições fornecidos pela Hiperbite para o uso dos seus serviços. Eles abrangem vários aspectos, desde a relação contratual até questões de segurança e privacidade. Aqui está um resumo dos principais pontos:',
        Component: TermsAndConditions
    }, {
        key: 'privacy-policies',
        title: 'Política de Privacidade',
        descriptions: 'Abaixo descrevemos como uma Nós Hiperbite coleta, utiliza, armazena e protege as informações pessoais dos nossos  usuários ou clientes. ',
        Component: PrivacyPolicy
    },
]
const Legal = () => {
    const { id } = useParams()

    const [{ descriptions, title, Component }, setContent] = useState<any>({})
    useMemo(() => {
        setContent(pageContents?.find(({ key }: any) => key == id)??{})
    }, [id])
    return <LegalPage  >
        <Container>
            {Component &&
                <Grid container p={15}>
                    <Typography variant='h1' pb={5}>
                        {title}
                    </Typography>
                    <Typography variant='h5' pb={5}>
                        {descriptions}
                    </Typography>
                    <Component />
                </Grid>}
        </Container>

    </LegalPage>
}

export default Legal
