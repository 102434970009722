/*
=========================================================
* Nova erp React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Hiperbite (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Nova erp React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import Close from '@mui/icons-material/Close';
// Images
import bgImage from "assets/images/coupon/acdc.png";
import bgImageMobile from "assets/images/coupon/acdc_mobile.png";
import { ErrorMessage } from "@hookform/error-message";
import { z } from "zod";
import commonsEndpoints from "services/endpoints/commons.endpoints";
import { useApi } from "services/apiSlice";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMemo, useState } from "react";
import { Alert, CircularProgress, Snackbar, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { appsPricings } from "pages/Suport/Legal/LegalPage/sections/PricingMain";
import { currency } from "helper";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

import useMediaQuery from '@mui/material/useMediaQuery';

const schema = z.object({
  name: z.string().min(5),
  email: z.string().email(),
  telephone: z.string(),
});

function CouponPromotionForm({ handleClose }: any) {
  const navigate = useNavigate()
  const [step, setStep] = useState<any>(0)
  const [newData, setNewData] = useState<any>({ per: 30 })
  const [myNewData, setMyNewData] = useState<any>({ per: 30 })
  const [redirectToDemoElement, setRedirectToDemoElement] = useState<any>(null);
  const { data, resolve, loading, error } = useApi({
    service: commonsEndpoints.clientRequest.create
  })
  const { service, pack, price, months, per } = myNewData
  const { register, reset, formState: { errors }, watch, handleSubmit } = useForm({
    resolver: zodResolver(schema)
  });

  const matches = useMediaQuery('(min-width:990px)');


  useMemo(() => {
    setMyNewData(newData ?? {})
  }, [newData])
  useMemo(() => {
    if (data?.date) {
      handleClose();
      reset()
      Swal.fire({
        icon: "success",
        title: 'Pedido enviado',
        text: 'Pedido de adesão registado com  sucesso, receberá as instruções com os proximos passo pelo telefone ou E-mail'
      }).then(() => {
        setTimeout(()=>navigate('/'),100)
        
      });
    }
  }, [data])

  const Form = [Form1, Form2][step];
  const next = () => setStep(step + 1)
  const onSubmit = async (data: any) => {
    console.log(data);
    const newData = {
      type: 5,//DEMO REQUEST

      message: JSON.stringify({ ...myNewData }),
      client: {
        email: "default@nova.ao",
        type: 0,
        country: "AO",
        ...data,
        ...myNewData
      }
    }

    resolve(newData);

  };

  return (

    <Grid container item sx={{ background: 'rgba(0,0,0,0)', paddingButton: 15 }}>
      <MKBox
        width="100%"
        borderRadius="xl"
        shadow="xl"
        mb={6}
        sx={{ backgroundColor: '#38B6FF' }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            position="relative"
            px={0}

          >
            <MKButton style={{ float: 'right' }} onClick={handleClose}>
              <Close />
            </MKButton>
          </Grid>
          <Grid
            item
            xs={12}
            lg={5}
            position="relative"
            px={0}

          >
            <MKBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
              height="100%"
              p={{ xs: -10, sm: 8 }} my="auto"
              py={2}
            >
              <img src={matches ? bgImage : bgImageMobile} alt="" style={{ width: '100%' }} />

            </MKBox>
          </Grid>
          <Grid item xs={12} lg={7}>
            <MKBox component="form" p={2} onSubmit={handleSubmit(onSubmit)}>
              <MKBox px={3} py={{ xs: 0, sm: 6 }}>
                <Grid sx={{ display: { xs: 'none', sm: 'block' } }}>
                  <MKTypography variant="h2" sx={{ color: '#FFF' }}>
                    Preencha o formulário para beneficiar-se do desconto
                  </MKTypography>
                </Grid>
              </MKBox>
              <MKBox pr={2} sx={{ overflowY: 'auto' }}>
                <Form errors={errors} register={register} loading={loading} data={newData} setData={setNewData} setStep={setStep} />

              </MKBox>
            </MKBox>
          </Grid>
        </Grid>
      </MKBox>
    </Grid>

  );
}

const apps = [
  { id: 0, code: 'rh', title: 'RH' },
  { id: 1, code: 'sales', title: ' Facturação' },
  //{ id: 2, code: 'sales', title: '' },
  { id: 3, code: 'accounting', title: ' Contabilidade' }
]
const schemaForm1 = z.object({
  name: z.string().min(5),
  email: z.string().email(),
  telephone: z.string(),
});
const Form1 = ({ loading, register, errors, setData, data, setStep }: any) => {

  const [service, setService] = useState('sales')
  const [currentService, setCurrentService] = useState(appsPricings[1])

  const [pack, setPack] = useState<string>(data?.pack)
  const [currentPack, setCurrentPack] = useState({})

  const [months, setMonths] = useState<number>(data?.months ?? 3)
  const [price, setPrice] = useState<number>(0)

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    setService(newAlignment);
    //if (!pack) setPack('Basic')
    setCurrentService(appsPricings[`${Number(event?.currentTarget?.id)}`])
  };


  useMemo(() => {
    const i = apps.find(({ code }: any) => code === data?.service)?.code ?? 0
    if (!currentService)
      setCurrentService(appsPricings[Number(i)])
  }, [data?.service])
  const handleMonthsChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: number,
  ) => {
    setMonths(newAlignment);
  };
  useMemo(() => setData({ ...data, months }), [months])
  useMemo(() => setData({ ...data, pack, price }), [pack])
  useMemo(() => setData({ ...data, service }), [service])
  const handlePlansChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    setPack(newAlignment);
    //  setPack('Basic')

    setPrice(Number(event?.currentTarget?.id))
  };
  return (
    <Grid item xs={12} sx={{ color: '#FFF' }} >
      <MKBox>
        <Grid container>
          <Grid item xs={12} pr={1} mb={2}>
            <label style={{ color: '#FFF' }}>Escolha a Aplicação</label>
            <br />
            <ToggleButtonGroup
              sx={{ background: '#FFF' }}
              color="primary"
              value={service}
              exclusive
              onChange={handleChange}
              aria-label="Platform"
            >
              {apps.map(({ code, title, id }: any) => <ToggleButton id={`${id}`} value={code}><h3>{title}</h3></ToggleButton>)}

            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={12} pr={1} mb={2}>
            <label style={{ color: '#FFF' }}>Escolha o Plano</label><br />
            <ToggleButtonGroup
              sx={{ background: '#FFF' }}
              color="primary"
              value={pack}
              exclusive
              onChange={handlePlansChange}
              aria-label="Platform"
            >
              {currentService?.filter(({ active }: any) => active)?.map(({
                title,
                price: { value }
              }: any) => <ToggleButton id={value} value={title}><div><h4>{title}</h4><h5>{currency(value)}/mês</h5></div></ToggleButton>)}
            </ToggleButtonGroup>

          </Grid>
          <Grid item xs={12} pr={1} mb={2}>
            <label style={{ color: '#FFF' }}>Quantos Meses</label><br />
            <ToggleButtonGroup
              sx={{ background: '#FFF' }}
              color="primary"
              value={months}
              exclusive
              onChange={handleMonthsChange}
              aria-label="Platform"
            >
              <ToggleButton id={"3"} value={3}>  <h2>3 </h2></ToggleButton>
              <ToggleButton id={"6"} value={6}>  <h2>6 </h2></ToggleButton>
              <ToggleButton id={"12"} value={12}><h2>12</h2></ToggleButton>
              <ToggleButton id={"24"} value={24}><h2>24</h2></ToggleButton>
              <ToggleButton id={"36"} value={36}><h2>36</h2></ToggleButton>
              <ToggleButton id={"48"} value={48}><h2>48</h2></ToggleButton>
              <ToggleButton id={"60"} value={60}><h2>60</h2></ToggleButton>
            </ToggleButtonGroup>
          </Grid>

          <Grid container >
            <Grid item xs={6} pr={1} mb={6}>
              <MKTypography variant="caption" mb={1} sx={{ color: '#FFF' }}>
                Normal
              </MKTypography>
              <MKTypography variant="h4" mb={1} sx={{ color: '#FFF', textDecoration: 'line-through' }}>
                {currency(price * months)}
              </MKTypography>

              <MKTypography variant="body2" mb={1} sx={{ color: '#FFF' }}>
                Poupe {currency(price * months * 30 / 100)} KZS
              </MKTypography>
            </Grid>
            <Grid item xs={6} pr={1} mb={6}>
              <MKTypography variant="caption" mb={1} sx={{ color: '#FFF' }}>
                A pagar
              </MKTypography>

              <MKTypography variant="h3" mb={1} sx={{ color: '#FFF' }}>
                {currency(price * months - price * months * 30 / 100)}
              </MKTypography>

            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={12}
          md={6}
          justifyContent="flex-end"
          textAlign="right"
          ml="auto"
        >
          <MKButton variant={'gradient'} color={"info"} onClick={() => setStep(1)} disabled={loading || (!price)}>
            {loading ? <><CircularProgress /> {' '}
              Enviando
            </> :
              <>
                Seguinte <ChevronRight />
              </>}
          </MKButton>

        </Grid>
      </MKBox>
    </Grid>
  )
}

const schemaForm2 = z.object({
  name: z.string().min(5),
  email: z.string().email(),
  telephone: z.string(),
});
const Form2 = ({ loading, register, errors, setData, data, setStep }: any) => {
  const { price, months } = data

  return (
    <MKBox pt={3} pb={3} px={3} sx={{ background: '#FFF' }}>
      <Grid container>
        <Grid item xs={12} pr={1} mb={6}>
          <MKInput
            label="Seu nome"
            placeholder="Seu Nome completo"
            fullWidth
            {...register("name", { required: "Este campo é obrigatorio." })}
          />
          <ErrorMessage errors={errors} name="name" />
        </Grid>
        <Grid item xs={12} pr={1} mb={6}>
          <MKInput
            label="E-mail"
            placeholder="E-mail"
            fullWidth
            {...register("email", { required: "Este campo é obrigatorio." })}
          />
          <ErrorMessage errors={errors} name="email" />
        </Grid>
        <Grid item xs={12} pr={1} mb={6}>
          <MKInput
            label="Telefone"
            placeholder="Número de telefone"
            fullWidth
            {...register("telephone", { required: "Este campo é obrigatorio." })}
          />
          <ErrorMessage errors={errors} name="telephone" />
        </Grid>
      </Grid>
      <Grid container >
            <Grid item xs={6} pr={1} mb={6}>
              <MKTypography variant="caption" mb={1} sx={{ }}>
                Normal
              </MKTypography>
              <MKTypography variant="h4" mb={1} sx={{ textDecoration: 'line-through' }}>
                {currency(price * months)}
              </MKTypography>

              <MKTypography variant="body2" mb={1} sx={{  }}>
                Poupe {currency(price * months * 30 / 100)} KZS
              </MKTypography>
            </Grid>
            <Grid item xs={6} pr={1} mb={6}>
              <MKTypography variant="caption" mb={1} sx={{  }}>
                A pagar
              </MKTypography>

              <MKTypography variant="h3" mb={1} sx={{ }}>
                {currency(price * months - price * months * 30 / 100)}
              </MKTypography>

            </Grid>
          </Grid>
      <Grid container>
        <Grid
          item
          xs={6}
          ml="auto"
        >
          <MKButton variant={'gradient'} color={"secondary"} type="button" onClick={() => setStep(0)} disabled={loading} sx={{ float: 'left' }}>
            <ChevronLeft />Voltar
          </MKButton>

        </Grid>
        <Grid

          item
          xs={6}
          md={6}
          justifyContent="flex-end"
          textAlign="right"
          ml="auto"
        >
          <MKButton variant={'gradient'} color={"info"} type="submit" disabled={loading}>
            {loading ? <><CircularProgress /> {' '}
              Enviando
            </> :
              <>
                Aderir
              </>}
          </MKButton>
        </Grid>
      </Grid>
    </MKBox >
  )
}
const Form3 = () => {

}
export default CouponPromotionForm;
