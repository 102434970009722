/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Nova erp React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Hiperbite (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import {useState, useEffect} from "react";
import { Alert, CircularProgress, Container, Grid, Snackbar } from "@mui/material";

// Nova erp React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Images
import macbook from "assets/images/macbook.png";
import { z } from "zod";
import { ErrorMessage } from "@hookform/error-message";
import { useMemo } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useApi } from "services/apiSlice";
import commonsEndpoints from "services/endpoints/commons.endpoints";

function Newsletter() {

  const [open, setOpen] = useState<boolean>(false);
  const { data, resolve, loading, error } = useApi({
    service: commonsEndpoints.clientRequest.create
  })
  const { register, reset, formState: { errors }, watch, handleSubmit } = useForm({
    resolver: zodResolver(schema)
  });

  useMemo(() => data?.date && reset(), [data])
  useEffect(() =>{
    if(data?.date!=undefined) setOpen(true);
  },[data])
  
  const onSubmit = async (data: any) => {
      const newData = {
      type: 1,//DEMO REQUEST
      client: {
        name: "default",
        email: "default@nova.ao",
        type: 0,
        telephone: 0,
        country: "AO",
        ...data
      }
    }

    resolve(newData);

  };

  return (
    <MKBox component="section" pt={6} my={6}>
      <Container>
        <Grid container alignItems="center">

          <Grid item md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
            <MKTypography variant="h4">Seja o primeiro a ver as novidades</MKTypography>
            <MKTypography variant="body2" color="text" mb={3}>
              Sua empresa pode não estar no ramo de software, mas, eventualmente, uma empresa de software estará no seu negócio.
            </MKTypography>
            <MKBox component="form" p={2} onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={1}>
                <Grid item xs={8}>
                  <MKInput type="email" label="Digite o seu E-mail" fullWidth
                    {...register("email", { required: "Este campo é obrigatorio." })}
                  />
                  <ErrorMessage errors={errors} name="email" />
                </Grid>
                <Grid item xs={4}>
                  <MKButton variant="gradient" type="submit" color="info" disabled={loading}>
                    {loading ? <><CircularProgress color="warning" size={25} /> processando </> :
                      <>Subscreva</>}
                  </MKButton>
                </Grid>
              </Grid>
            </MKBox>
          </Grid>
          <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
            <MKBox position="relative">
              <MKBox component="img" src={macbook} alt="macbook" width="100%" />
            </MKBox>
          </Grid>
        </Grid>
      </Container>

      <Snackbar
        sx={{ maxWidth: 600, width: '100%' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}

      >
        <Alert sx={{ maxWidth: 600, width: '100%' }} variant="filled" severity="success">Subscreveu com sucesso na nossa newsletter, doravante receberá as ultimas novidades na sua caixa de E-mail</Alert>

      </Snackbar>
    </MKBox>
  );
}


const schema = z.object({
  email: z.string().email("email inválido"),
});

export default Newsletter;
