/*
=========================================================
* Nova erp React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Hiperbite (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Nova erp React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import Close from '@mui/icons-material/Close';
// Images
import bgImage from "assets/images/examples/blog2.jpg";
import { ErrorMessage } from "@hookform/error-message";
import { z } from "zod";
import commonsEndpoints from "services/endpoints/commons.endpoints";
import { useApi } from "services/apiSlice";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMemo, useState } from "react";
import { Alert, CircularProgress, Snackbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const schema = z.object({
  name: z.string().min(5),
  email: z.string().email(),
  telephone: z.string(),
});

function RequestDemoForm({ handleClose }: any) {
  const navigate = useNavigate()
  const [redirectToDemoElement, setRedirectToDemoElement] = useState<any>(null);
  const { data, resolve, loading, error } = useApi({
    service: commonsEndpoints.clientRequest.create
  })
  const { register, reset, formState: { errors }, watch, handleSubmit } = useForm({
    resolver: zodResolver(schema)
  });

  useMemo(() => {
    if (data?.date) {

      Swal.fire({
        icon: "success",
        title: 'Pedido enviado',
        text: 'Pedido de demostração enviado com sucesso, seras reencaminhado para a aplciação de demostração'
      });
      redirectToDemoElement?.click();
      reset()
    }
  }, [data])


  const onSubmit = async (data: any) => {
    console.log(data);
    const newData = {
      type: 2,//DEMO REQUEST
      client: {
        email: "default@nova.ao",
        type: 0,
        country: "AO",
        ...data
      }
    }

    resolve(newData);

  };

  return (
    <MKBox component="section" py={{ xs: 0, lg: 6 }}>
      <Container>
        <Grid container item>
          <MKBox
            width="100%"
            bgColor="white"
            borderRadius="xl"
            shadow="xl"
            mb={6}
            sx={{ overflow: "hidden" }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                lg={5}
                position="relative"
                px={0}
                sx={{
                  backgroundImage: ({
                    palette: { gradients },
                    functions: { rgba, linearGradient },
                  }: any) =>
                    `${linearGradient(
                      rgba(gradients.dark.main, 0.8),
                      rgba(gradients.dark.state, 0.8)
                    )}, url(${bgImage})`,
                  backgroundSize: "cover",
                }}
              >
                <MKBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  height="100%"
                >
                  <MKBox py={6} p={{ xs: 3, sm: 12 }} my="auto">
                    <MKTypography variant="h2" color="white" >
                      Demonstração do Nosso Software ERP
                    </MKTypography>
                    <Grid sx={{ display: { xs: 'none', sm: 'block' } }}>
                      <MKTypography variant="body2" color="white" opacity={0.8} mb={3}>
                        Uma solução completa e integrada para otimizar a gestão de recursos da sua empresa. Acesse a verão de demonstração, vamos guiá-lo através de algumas das funcionalidades-chave que podem impulsionar a eficiência e o desempenho do seu negócio.
                      </MKTypography>

                      <MKTypography variant="body2" color="white" opacity={0.8} mb={3}>
                        Esta é apenas uma prévia do que o nosso software ERP pode oferecer. Ficou interessado? Entre em contato para agendar uma demonstração guiada, completa e personalizada. Estamos ansiosos para ajudar a impulsionar o sucesso do seu negócio com nossa solução abrangente.


                      </MKTypography>
                    </Grid>

                  </MKBox>
                </MKBox>
              </Grid>
              <Grid item xs={12} lg={7}>
                <MKBox component="form" p={2} onSubmit={handleSubmit(onSubmit)}>

                  <MKButton style={{ float: 'right' }} onClick={handleClose}>
                    <Close />
                  </MKButton>
                  <MKBox px={3} py={{ xs: 0, sm: 6 }}>
                    <Grid sx={{ display: { xs: 'none', sm: 'block' } }}>
                      <MKTypography variant="h2" mb={1}>
                        Preencha o formulário para solicitar
                      </MKTypography>
                    </Grid>
                    <MKTypography variant="body1" color="text" mb={2}>

                    </MKTypography>
                  </MKBox>
                  <MKBox pt={0.5} pb={3} px={3}>
                    <Grid container>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          label="Seu nome"
                          placeholder="Seu Nome completo"
                          fullWidth
                          {...register("name", { required: "Este campo é obrigatorio." })}
                        />
                        <ErrorMessage errors={errors} name="name" />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          label="E-mail"
                          placeholder="E-mail"
                          fullWidth
                          {...register("email", { required: "Este campo é obrigatorio." })}
                        />
                        <ErrorMessage errors={errors} name="email" />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          label="Telefone"
                          placeholder="Número de telefone"
                          fullWidth
                          {...register("telephone", { required: "Este campo é obrigatorio." })}
                        />
                        <ErrorMessage errors={errors} name="telephone" />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={6}
                      justifyContent="flex-end"
                      textAlign="right"
                      ml="auto"
                    >
                      <MKButton variant={'gradient'} color={"info"} type="submit" disabled={loading}>
                        {loading ? <><CircularProgress /> {' '}
                          Enviando
                        </> :
                          <>
                            Acessar a versão de demostração
                          </>}
                      </MKButton>
                      <a href="https://demo.nova.ao" target="_blank" style={{opacity:0, fontSize:2}} ref={(input: any) => setRedirectToDemoElement(input)} >https://demo.nova.ao</a>
                    </Grid>
                  </MKBox>
                </MKBox>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>

  );
}
export default RequestDemoForm;
