/*
=========================================================
* Nova erp React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Hiperbite (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Nova erp React components
import MKBox from "components/MKBox";

// Nova erp React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/doubt.jpg";
import bgBack from "assets/images/doubt.jpg";

function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title={
                  <>
                    Porquê o 
                    <br />
                    Nova erp?
                  </>
                }
                description=" é um software de gestão Empresarial inovador. Além do habitual controlo de facturação, contas correntes, stocks, encomendas e avenças. "
              />
              <RotatingCardBack
                image={bgBack}
                title="Aderir agora"
                description="Ótimo! Se você está interessado em aderir ao nosso sistema ERP, clica a baixo"
                action={{
                  type: "internal",
                  route: "/prices",
                  label: "Aderir",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="content_copy"
                  title="Atualizações Automáticas"
                  description="As atualizações são gerenciadas por nós, isso significa que sua empresa sempre terá á versão mais recentes e atualizadas."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="flip_to_front"
                  title="Segurança de Dados"
                  description="Medidas de segurança robustas, incluindo criptografia de dados, firewalls avançados e monitoramento constante."
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="price_change"
                  title="Redução de Custos de Infraestrutura"
                  description="Não é necessário investir em servidores físicos e infraestrutura local, o que resulta em economias significativas em custos de hardware e manutenção."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="devices"
                  title="Completamente portatil"
                  description="Acesso móvel é facilitado no nosso sistemas ERP na nuvem, permitindo que os funcionários acessem informações e realizem tarefas a partir de dispositivos móveis, aumentando a produtividade."
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
