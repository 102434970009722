
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import BasicSpeedDial from '@mui/material/SpeedDial';
import { Phone } from '@mui/icons-material';
import { Alert, CircularProgress, Container, Grid, InputAdornment, Popover, Snackbar, Typography } from '@mui/material';
import MKButton from 'components/MKButton';
import MKBox from 'components/MKBox';
import MKInput from 'components/MKInput';
import { useForm } from "react-hook-form";

import { ErrorMessage } from '@hookform/error-message';
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useApi } from 'services/apiSlice';
import commonsEndpoints from 'services/endpoints/commons.endpoints';
import { MouseEvent, useMemo, useState } from 'react';
import Swal from 'sweetalert2';

export default function SpeedDial() {

  const [{ data, loading, error }, setState]: any = useState({ data: null, loading: false, error: null });
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  useMemo(() => {
    if (data?.date) {
      Swal.fire({
        icon: "success",
        title: 'Pedido enviado',
        text: 'Pedido de ligação enviado com sucesso, dentro em breve receberá um ligação de um assistente comercial'
      });
      handleClose()
    }
  }, [data])
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Box sx={{ height: 330, position: 'fixed', bottom: '.5em', right: anchorEl===null ? 0 :-200, zIndex: 99999, transition:'.1s', transform: 'translateZ(0px)', flexGrow: 1 }}>
        <Backdrop open={open} />
        <MKButton
          color='warning'
          variant={'gradient'}
          ariaLabel="SpeedDial tooltip example"
          sx={{ position: 'absolute', bottom: '.05em', right: 0, padding: 2, borderRadius: 50 }}
          onClose={handleClose}
          open={open}
          onClick={(e: any) => handleClick(e)}
        ><Box sx={{width:200, textTransform:'none'}}>
          <Phone fontSize="large"  /> Nós ligamos pra si, é gratis
        </Box>
          
        </MKButton>
      </Box>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        color='#FFF'
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <PopOverForm
          setState={setState} />

      </Popover>
    </>
  );
}

const schema = z.object({
  name: z.string(),
  telephone: z.number()
});
const PopOverForm = ({ setState }: any) => {

  const { data, resolve, loading, error } = useApi({
    service: commonsEndpoints.clientRequest.create
  })
  const { register, reset, formState: { errors }, watch, handleSubmit } = useForm({
    resolver: zodResolver(schema)
  });

  useMemo(() => data?.date && reset(), [data])
  useMemo(() => {
    if (register !== null) {
      setState({ data, error, loading })
    }
  }, [loading])
  const onSubmit = async (data: any) => {
    console.log(data);
    const newData = {
      type: 0,//CALL INTENTION
      client: {
        email: "default@nova.ao",
        type: 0,
        country: "AO",
        ...data
      }
    }

    resolve(newData);

  };

  return (<>
    <MKBox component="section" py={12} sx={{ p: 2, background: '#DDD', }} color={"info"} variant={"gradiant"}>

      <Container>
        <form onSubmit={handleSubmit(onSubmit)}>
          {!loading && <><Grid container item xs={12} py={1} mx="auto">
            <Typography>
              Deixe o seu contacto<br />e nós ligamos para si
            </Typography>
          </Grid>
            <Grid container item xs={12} py={1} mx="auto">
              <MKInput
                placeholder="Nome completo"
                fullWidth
                {...register("name", { required: "Este campo é obrigatorio." })}
              />
              <ErrorMessage errors={errors} name="name" />
            </Grid>
            <Grid container item xs={12} py={1} mx="auto">
              <MKInput
                placeholder="Telefone"
                fullWidth
                type="number"
                {...register("telephone", { valueAsNumber: true, required: "Este campo é obrigatorio." })}
              />
              <ErrorMessage errors={errors} name="telephone" />
            </Grid>
          </>}
          <Grid container item xs={12} py={1} mx="auto" justifyContent={'right'}>
            <MKButton variant={'gradient'} color={"info"} type="submit" disabled={loading}>
              {loading ? <><CircularProgress /> {' '}
                Enviando
              </> :
                <>
                  Enviar
                </>}
            </MKButton>
          </Grid>
        </form>
      </Container>
    </MKBox>
  </>
  );
}