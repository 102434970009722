import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MKButton from 'components/MKButton';
import CouponPromotionForm from './CouponPromotionForm';
import { useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';

import { Dialog, useMediaQuery, useTheme } from '@mui/material';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function CouponPromotion({ text = 'cupon' }: any) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [searchParams, setSearchParams] = useSearchParams()

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme?.breakpoints?.down('md'));
    useMemo(() => {
        let query = searchParams.get('bouce')
        if (query?.toLocaleLowerCase() === 'acdc') {
            setOpen(true)
        }
    }, [searchParams.get('bouce')])
    return (
        <>
            {/*
            <MKButton variant="gradient" color="info" onClick={handleOpen}>
                {text} { }
    </MKButton>*/}
            {open &&
                <Dialog
                    fullScreen={fullScreen}

                    maxWidth={'lg'}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                >
                    <CouponPromotionForm handleClose={handleClose} />
                </Dialog>}
        </>
    );
}

