import React from 'react'
import data from './tems-and-conditions.json'
import { Container, Grid, Typography } from '@mui/material'
const TermsAndConditions = () => {
    return (
        <Grid container p={15}>
            <ol>
                {data?.map((childItem: any) => <List item={childItem} />)}
            </ol>
        </Grid>

    )
}

const List = ({ item: { title, list } }: any) => {
    return (
        <li>
            {title}
            {list && <ol style={{ paddingLeft: 30, paddingBottom: 15 }}>{list?.map((childItem: any) => <List item={childItem} />)}</ol>}
        </li>
    )


}
export default TermsAndConditions
