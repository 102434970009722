let endpoint;
endpoint = "application";

type methodTypes = "PUT" | "POST" | "GET" | "DELETE"

const application = {
    get: { endpoint, method: "GET" },
    update: { endpoint, method: "PUT" },
    create: { endpoint, method: "POST" },
    drop: { endpoint, method: "DELETE" },
}

endpoint = "client";

const client = {
    get: { endpoint, method: "GET" },
    update: { endpoint, method: "PUT" },
    create: { endpoint, method: "POST" },
    drop: { endpoint, method: "DELETE" },
}

endpoint = "client-request";

const clientRequest = {
    get: { endpoint, method: "GET" },
    update: { endpoint, method: "PUT" },
    create: { endpoint, method: "POST" },
    drop: { endpoint, method: "DELETE" },
}


export default { application, client, clientRequest };

