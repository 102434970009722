import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import { Tooltip } from '@mui/material';
import { ArrowRight } from '@mui/icons-material';

function SearchInput() {
    return (
        <Paper
            component="form"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
        >
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Pesquisar por ..."
                inputProps={{ 'aria-label': 'search google maps' }}
            />

            <Tooltip title="Pesquisar">
                <IconButton
                    size="large"
                    sx={{
                        '& svg': {
                            color: 'rgba(255,255,255,0.8)',
                            transition: '0.2s',
                            transform: 'translateX(0) rotate(0)',
                        },
                        '&:hover, &:focus': {
                            bgcolor: 'unset',
                            '& svg:first-of-type': {
                                transform: 'translateX(-4px) rotate(-20deg)',
                            },
                            '& svg:last-of-type': {
                                right: 0,
                                opacity: 1,
                            },
                        },
                        '&:after': {
                            content: '""',
                            position: 'absolute',
                            height: '80%',
                            display: 'block',
                            left: 0,
                            width: '1px',
                            bgcolor: 'divider',
                        },
                    }}
                >
                    <SearchIcon />
                    <ArrowRight sx={{ position: 'absolute', right: 4, opacity: 0 }} />
                </IconButton>
            </Tooltip>

            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

        </Paper>
    );
}

export default SearchInput
