/*
=========================================================
* Nova erp React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Hiperbite (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Nova erp React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Nova erp React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
const faqs = [

  { question: 'Por que escolher o Software de RH da Nova erp?', answer: 'Sabemos que não os somos únicos, mas queremos ser únicos para você. Não somos o Software de RH típico que sua empresa compra e você nunca mais tem contato com ninguém.\nNossos pilares com nossos clientes são eficiência, suporte e melhoria contínua. É por isso que, do primeiro ao último contato, você terá uma pessoa designada para te orientar e uma equipe pronta para tirar qualquer dúvida e entender quais recursos e ferramentas podemos melhorar. ' },

  { question: 'Que tipo de Software de RH é a Nova erp?', answer: 'Somos um Software de RH completo. Isso significa que permitimos que você centralize todos os processos de RH e DP em um único sistema, desde tarefas cotidianas como o controle de ponto dos funcionários, férias e faltas, passando pelo armazenamento de documentos ou assinaturas eletrônicas até a possibilidade de criar avaliações de desempenho de forma automatizada.' },

  { question: 'Quais tipos empresas podem usar a plataforma Nova erp?', answer: 'Uma como a sua, provavelmente! Nosso Software de RH foi inicialmente pensado para pequenas e médias empresas, ou seja, fomos especialmente feitos para PMEs. Mas hoje já podemos atender empresas com até 2 mil funcionários.' },
  { question: 'Que tipos de acesso a Nova erp oferece?', answer: 'Este não é uma plataforma apenas para os profissionais de recursos humanos! Nos orgulhamos muito de poder facilitar também as tarefas diárias dos gestores e de cada um dos colaboradores das empresas que nos escolhem. Nosso software oferece diferentes tipos de acesso e possibilidades para o RH, liderança e funcionário. Basta selecionar o tipo de perfil e os recursos que serão oferecidos em cada caso.' },


  { question: 'Como a Nova erp melhora a gestão de RH?', answer: 'Transformando um departamento de Recursos Humanos em um departamento de pessoas. O Software de RH da Nova erp permite que você esqueça todas aquelas tarefas manuais repetidas diariamente, semanalmente e mensalmente para ganhar tempo e poder se concentrar em objetivos muito mais estimulantes, como o crescimento de sua equipe. ' },
  { question: 'Que tarefas posso automatizar com a Nova erp?', answer: 'Acredite, a gente tentou chegar a um número, mas as possibilidades são muitas! Mas para você ter uma ideia, nossas principais áreas de foco estão relacionadas à gestão do tempo (relógio de ponto, planejamento de turnos, controle de férias), gestão de talentos (todas as tarefas típicas de admissão, integração e performance, por exemplo), e às tarefas jurídicas e financeiras (desde o controle de despesas até as variações na folha de pagamento).' },

  { question: 'A Nova erp também atende ao DP? ', answer: '100%! As funcionalidades do software de gestão de pessoas atendem tanto às demandas do departamento de recursos humanos quanto do departamento pessoal. Oferecemos diversas possibilidades para automatizar tarefas, agilizar processos burocráticos do DP e centralizar as demandas do RH. Tudo no mesmo software, dá para acreditar?' },
  { question: 'Posso contratar só algumas funcionalidades ou cancelar o plano a qualquer momento?', answer: 'Com certeza. Queremos que você pague apenas pelo que precisa. Cada empresa tem a sua dimensão e necessidades, por isso desenvolvemos diferentes planos e pacotes para que você possa personalizar o seu de acordo com as necessidades da sua empresa.\nEm nossa página de consulta de preços você encontrará opções a partir de R$ 20,00 por funcionário, por mês.\nocê também pode calcular o custo do seu plano ideal usando a nossa calculadora de planos.\nE como em qualquer relacionamento saudável, queremos que você seja livre para decidir quando quer estar com a gente ou não. Você pode experimentar o nosso software sem compromisso e cancelar o plano a qualquer momento depois da assinatura, mas temos certeza que no primeiro contato já vai nos querer bem pertinho!' }]
function Faqs() {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              Ficou com alguma dúvida?
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
              Estas são algumas das perguntas mais frequentes que recebemos sobre nosso Software de RH
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          {faqs.map(({ question, answer }: any, i: number) => (
            <Accordion expanded={expanded === 'panel' + i} onChange={handleChange('panel' + i)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={"panel" + i + "a-content"}
                id={"panel" + i + "a-header"}
              >
                <Typography>{question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {answer}
                </Typography>
              </AccordionDetails>
            </Accordion>))}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Faqs;
