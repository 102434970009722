import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Button, Card, Divider, List, ListItem, ListItemText, Modal } from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ListItemButton, ListItemIcon } from '@mui/material';

import { Check } from '@mui/icons-material'
import MKButton from 'components/MKButton';
import StartBuyForm from './StartBuyForm';
import { currency } from 'helper';
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function PricingCard({
  app,
  color = "dark",
  title = "Starter",
  description = "2-10 Funcionários",
  price = { value: "$199", type: "year" },
  action = { type: "internal", route: "/", label: "buy now" },
  specifications = ["Complete documentation", "Working materials in Sketch"]
}: any) {
  const [expanded, setExpanded] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const content = <Card sx={{ maxWidth: 420, padding: 3 }} color={color} >
    <CardHeader

      title={title}
      subheader={title}
    />
    <CardContent>
      <Typography variant="body2" color="text.secondary" sx={{ width: '100%', fontSize: 12, maxWidth: 360, bgcolor: 'background.paper' }}>
        Para Microempresas que estão começando e têm necessidades básicas de software. Geralmente, essas empresas têm uma equipe pequena e não exigem funcionalidades muito avançadas. Este pacote oferece um conjunto essencial de recursos a um preço acessível.
      </Typography>
      <List sx={{ width: '100%', fontSize: 12, bgcolor: 'background.paper' }}>
        <ListItem>
          <ListItemButton>
            <ListItemText>
              <Typography sx={{ fontSize: 12 }} color="text.secondary">

                {description}
              </Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>
        {specifications?.map((specification: string) =>
          <ListItem>
            <ListItemButton>
              <ListItemIcon sx={{ margin: '0px -25px' }}>
                <Check />
              </ListItemIcon>
              <ListItemText>
                <Typography sx={{ fontSize: 12 }} color="text.secondary">
                  {specification}
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>)}
      </List>
    </CardContent>
    <CardActions disableSpacing>
      <Divider />
      <Box alignContent={'space-between'} alignItems={'column'} flex={'column'}>
        <Typography variant="h3" color="text.secondary">
          {currency(price.value)}/<span style={{ fontSize: 16 }} color="text.secondary">
            {price.type}
          </span>
        </Typography>
        <MKButton
          rel="noreferrer"
          variant="gradient"
          size="small"
          color={"dark"}
          onClick={handleOpen}
        >
          {action.label}
        </MKButton>
      </Box>
    </CardActions>
  </Card>
  return (<>
    {content}

    <Modal
      keepMounted
      open={open}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <StartBuyForm handleClose={handleClose}
        data={
          {
            app,
            title,
            description,
            price,
            specifications
          }
        }
      >{content}</StartBuyForm>

    </Modal>

  </>
  );
}


