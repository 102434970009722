
import { Button } from '@mui/material';
import Carousel from 'react-material-ui-carousel';

interface ItemProps{
    item : any
}

function Item({item}:ItemProps){
    return(
    <>
        <p>{item.description}</p>

        <Carousel
             NavButton={({className, style, next, prev}) => {
    
             return (
            <Button className={className} style={style} >
                {next && "Next"}
                {prev && "Previous"}
            </Button>
                 )
               }}
        >
        </Carousel>
    </>
          )
}

export default Item 